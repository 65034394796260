import { CompanyContext } from 'contexts/CompanyContextProvider';
import React, { MutableRefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EpdRoutes, { EpdLinks } from 'routes/EpdRoutes';
import EpdService from 'services/EpdService';
import { ButtonSmall } from 'styles/Styles.styled';
import { Modal } from 'util/Modal';

import { ProcessStatus } from '../../constants/constants';
import CopyEpd from './CopyEpd';

const CreateEpd: React.FunctionComponent<{
  anyEpd: boolean;
}> = ({ anyEpd }) => {
  const history = useHistory();
  const modalRef = useRef<Modal>();
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);
  const { t } = useTranslation();
  const [copying, setCopying] = useState<boolean>(false);
  const { companyId, companyAccountId } = React.useContext(CompanyContext);

  const createEpd = async () => {
    if (!companyAccountId) {
      return;
    }
    try {
      setStatus(ProcessStatus.Fetching);
      const newEpd = await EpdService.createEpd(companyAccountId);
      const route = EpdLinks.epd(newEpd.id);
      history.push(route);
      setStatus(ProcessStatus.Success);
    } catch {
      setStatus(ProcessStatus.Error);
    }
  };

  if (!companyAccountId) {
    return null;
  }

  const onCloseDialog = () => {
    setCopying(false);
  };

  return (
    <div style={{ marginLeft: '1rem' }}>
      {copying && companyId ? (
        <Modal ref={modalRef as MutableRefObject<Modal>}>
          <CopyEpd companyId={companyId} accountId={companyAccountId} onClose={onCloseDialog} />
        </Modal>
      ) : null}
      <ButtonSmall
        onClick={(e) => {
          status === ProcessStatus.Fetching ? e.preventDefault() : createEpd();
        }}
      >
        {status === ProcessStatus.Fetching ? t('epdDashboard.newEpd.creating') : t('epdDashboard.newEpd.created')}
      </ButtonSmall>
      {anyEpd && (
        <>
          <ButtonSmall style={{ marginLeft: '1rem' }} onClick={() => history.push(EpdRoutes.copyEpd)}>
            {t('epdDashboard.copyEpd.created')}
          </ButtonSmall>
        </>
      )}
    </div>
  );
};

export default CreateEpd;
