import {
  AddCollectionEPDModel,
  CollectionsClientV2,
  CreateCollectionModel,
  FileParameter,
  UpdateCollectionPropertyModel,
  CollectionModel,
  SaveCollectionModel,
} from './EpdClient';

const client = new CollectionsClientV2(process.env.REACT_APP_API_URL);

class CollectionService {
  static getCollections = async (companyId?: string) => {
    return await client.getAll(companyId);
  };
  static getCollection = async (collectionId: string) => {
    return await client.get(collectionId);
  };
  static AddNewCollection = async (companyId?: string) => {
    const NewCompanyId: CreateCollectionModel = { companyId };
    return await client.create(NewCompanyId);
  };
  static updateProperty = async (collectionId?: string, propertyName?: string | undefined, newValue?: any | undefined) => {
    const newQuery: UpdateCollectionPropertyModel = { collectionId, propertyName, newValue };
    return await client.updateCollectionProperty(newQuery);
  };
  static deleteCollection = async (collectionId: string) => {
    return await client.delete(collectionId);
  };
  static addCollectionFile = async (collectionId?: string, propertyName?: string, file?: FileParameter) => {
    return await client.addCollectionFile(collectionId, propertyName, file);
  };
  static addCollectionEPD = async (collectionId: string, epdId: string) => {
    const collection: AddCollectionEPDModel = {
      collectionId: collectionId,
      propertyName: 'EPD',
      newValue: epdId,
    };
    return await client.addCollectionEPD(collection);
  };
  static removeCollectionEpd = async (collectionId: string, epdId: string) => {
    return await client.removeCollectionEPD(collectionId, epdId);
  };
  static removeCollectionFile = async (collectionId: string, fileId: string) => {
    return await client.removeCollectionFile(collectionId, fileId);
  };

  static saveCollection = async (collection: SaveCollectionModel) => {
    return await client.save(collection);
  };

  static publishCollection = async (id: string) => {
    return await client.publish(id);
  };

  static unpublishCollection = async (id: string) => {
    return await client.unpublish(id);
  };
}

export default CollectionService;
