import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/theme.css';

import './App.css';

import StartupDialogsComponent from 'components/dialogs/StartupDialogsComponent';
import FooterComponent from 'components/footer/FooterComponent';
import HeaderComponent from 'components/header/HeaderComponent';
import ApplicationLoadingSpinner from 'components/spinners/ApplicationLoadingSpinner';
import { ScrollTopButton } from 'components/v2/buttons';
import { ApplicationToastRoot } from 'components/v2/toast';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { useTheme } from 'hooks/useTheme';
import { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import NewUIRoutes from 'routes/NewUiRoutes';
import OldUIRoutes from 'routes/OldUiRoutes';
import styled, { ThemeProvider } from 'styled-components';

function App() {
  const [hasLoggedInBefore, setHasLoggedInBefore] = useState<boolean>(false);
  const [isLoadingState, setIsLoadingState] = useState<boolean>(true);

  const { refreshCompany, licensee } = useContext(CompanyContext);
  const { theme, changeTheme, isNewUI, onChangeUI } = useTheme(licensee);

  useEffect(() => {
    refreshCompany();
    setIsLoadingState(false);
    setHasLoggedInBefore(!!localStorage.getItem('loggedInBefore'));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {isLoadingState ? (
        <ApplicationLoadingSpinner />
      ) : (
        <Router>
          <HeaderComponent isNewUI={isNewUI} onChangeUI={onChangeUI} changeTheme={changeTheme} />

          <Body>
            {isNewUI ? <NewUIRoutes hasLoggedInBefore={hasLoggedInBefore} /> : <OldUIRoutes changeTheme={changeTheme} />}
          </Body>

          <FooterComponent isNewUI={isNewUI} />

          <Modal id="modal" />

          <StartupDialogsComponent />

          <ApplicationToastRoot />

          {isNewUI ? <ScrollTopButton icon="pi pi-arrow-circle-up" /> : null}
        </Router>
      )}
    </ThemeProvider>
  );
}

const Modal = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);

  > div {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .focus-lock {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

const Body = styled.div``;

export default App;
