import chevronRight from 'assets/images/icons/inputs/chevron-right.svg';
import ExplanationText from 'components/v2/ExplanationText';
import TitleAndBack from 'components/v2/TitleAndBack';
import { ActionPrimaryButton, ActionSecondaryButton } from 'components/v2/buttons';
import { confirmDelete } from 'components/v2/confirm-dialog/ConfirmDelete';
import { confirmGeneric } from 'components/v2/confirm-dialog/ConfirmGeneric';
import { confirmQuestion } from 'components/v2/confirm-dialog/ConfirmQuestion';
import Help from 'components/v2/copy/Help';
import StepBadge from 'components/v2/copy/StepBadge';
import useCopyWizardLogic from 'components/v2/copy/wizard/useCopyWizardLogic';
import WizardTextAreaComponent from 'components/v2/epd-wizard-inputs/WizardTextArea';
import { HelpBox } from 'components/v2/help-boxes';
import { Formik } from 'formik';
import { StepStatus } from 'pages/wizard/types';
import { BlockUI } from 'primereact/blockui';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import { EpdLinks } from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import { EPDCopyGroupStatus, EPDModel, VerificationType } from 'services/EpdClient';
import { Container, HeaderDialog, HyperLink } from 'styles/Styles.styled';
import Spinner from 'styles/spinner.styled';
import {
  ErrorTextBox,
  FormPanel,
  MainViewFull,
  Step,
  WizardHeaderButtonBox,
  WizardHeaderGrid,
} from '../../../styles/v2/Styles.styled';
import { withGreyBackground } from '../WithGreyBackground';

const CopyEpdWizard: React.FunctionComponent = () => {
  const {
    t,
    history,
    copyGroupId,
    groupData,
    steps,
    helpButtonRef,
    onPublish,
    onSendToPublish,
    onSendToVerification,
    onConfirmVerification,
    onConfirmCancelVerification,
    onConfirmGroupDelete,
    onApproveCopyByLicenseeAdmin,
    activeStepIndex,
    setActiveStepIndex,
    isExplanationVisible,
    setIsExplanationVisible,
    declinePublicationText,
    setDeclinePublicationText,
    setDeclinePublicationConfirmed,
    isUiBlocked,
    isUpdatingEpd,
    setDeclinePublicationByLicenseeAdminConfirmed
  } = useCopyWizardLogic();

  const initialValues = { groupName: '', collection: null, singleCopy: false };
  const confirmDeleteGroup = () => {
    confirmDelete({
      caption: `Are you sure you want to delete the group "${groupData.name}"?`,
      description: <div>The EPD data will be lost and you will not be able to restore it.</div>,
      onConfirm: onConfirmGroupDelete,
    });
  };

  const confirmCancelVerification = () => {
    confirmDelete({
      caption: `Are you sure you want to cancel verification process?`,
      description: <div>The EPD group verification will be cancelled.</div>,
      acceptLabel: 'Confirm',
      rejectLabel: 'Cancel',
      onConfirm: onConfirmCancelVerification,
    });
  };

  const confirmCancelPublication = () => {
    confirmGeneric({
      contents: (
        <>
          <HeaderDialog>Decline EPD Copy group publication</HeaderDialog>
          <HelpBox>
            Please describe the reason to decline publication of {groupData.name}. The EPD owner will be informed via e-mail
            about the reason of declined publication. The EPD owner will be informed via e-mail about the reason of declined
            verification.
          </HelpBox>

          <WizardTextAreaComponent
            label="Decline comment"
            defaultValue={''}
            value={declinePublicationText}
            onChanged={(e: any, v: any) => {
              setDeclinePublicationText((p: string) => v.toString());
            }}
            name="declinePublicationText"
          />
        </>
      ),
      acceptLabel: 'Confirm',
      rejectLabel: 'Cancel',
      onConfirm: () => setDeclinePublicationConfirmed(true),
    });
  };

  const confirmDeclinePublicationByLicenseeAdmin = () => {
    confirmGeneric({
      contents: (
        <>
          <HeaderDialog>Decline EPD Copy group publication</HeaderDialog>
          <HelpBox>
            Please describe the reason to decline publication of {groupData.name}. The EPD owner will be informed via e-mail
            about the reason of declined publication. The EPD owner will be informed via e-mail about the reason of declined
            verification.
          </HelpBox>

          <WizardTextAreaComponent
            label="Decline comment"
            defaultValue={''}
            value={declinePublicationText}
            onChanged={(e: any, v: any) => {
              setDeclinePublicationText((p: string) => v.toString());
            }}
            name="declinePublicationText"
          />
        </>
      ),
      acceptLabel: 'Confirm',
      rejectLabel: 'Cancel',
      onConfirm: () => setDeclinePublicationByLicenseeAdminConfirmed(true),
    });
  };

  const explanationMessages = {
    Draft: {
      Default: (
        <>
          With copy EPD functionality you can easily create similar EPDs based on EPD source template.{' '}
          <HyperLink onClick={() => helpButtonRef?.current?.click()}>Read full instruction here</HyperLink>
        </>
      ),
    },
    Reserved: {
      Default: (
        <>
          Please fill-in the information under Step 2 - Product information. Once you filled out all mandatory data you can
          start the verification process by clicking the button "Start verification".
        </>
      ),
    },
    'Sent for verification': {
      Default: <>EPD verification process in progress now. Please wait for EPD verifier's confirmation.</>,
    },
    Verified: {
      Default: (
        <>
          Your EPDs have been verified. If a certain EPD was declined after the verification - you can update, delete or
          unlink these EPDs from the group.You will be able to access the unlinked EPD from your dashboard. If you update
          declined EPDs you will be able to send the copy batch EPDs for verification. If you unlink or delete declined EPD
          from a copy batch you will be able to send the rest of approved EPDs for publishing.
        </>
      ),
    },
    'Sent for publication': { Default: <>No actions needed.</> },
    'Approved by Licensee Admin': { Default: <>No actions needed.</> },
    'Declined copy by Licensee Admin': {
      Default: (
        <>
          Your EPD copy group publishing has been declined. Once you update your EPD copies you will be able start
          verification or send to publish again.
        </>
      ),
    },
    'Declined publication': {
      Default: (
        <>
          Your EPD copy group publishing has been declined. Once you update your EPD copies you will be able start
          verification or send to publish again.
        </>
      ),
    },
  };

  const explanationMessagesAdmin = {
    Draft: {
      Default: (
        <>
          With copy EPD functionality you can easily create similar EPDs based on EPD source template.{' '}
          <HyperLink onClick={() => helpButtonRef?.current?.click()}>Read full instruction here</HyperLink>
        </>
      ),
    },
    Reserved: {
      Default: (
        <>
          Please fill-in the information under Step 2 - Product information. Once you filled out all mandatory data you can
          start the verification process by clicking the button "Start verification".
        </>
      ),
    },
    'Sent for verification': {
      Default: <>EPD verification process in progress now. Please wait for EPD verifier's confirmation.</>,
    },
    Verified: {
      Default: (
        <>
          Your EPDs have been verified. If a certain EPD was declined after the verification - you can update, delete or
          unlink these EPDs from the group.You will be able to access the unlinked EPD from your dashboard. If you update
          declined EPDs you will be able to send the copy batch EPDs for verification. If you unlink or delete declined EPD
          from a copy batch you will be able to send the rest of approved EPDs for publishing.
        </>
      ),
    },
    'Sent for publication': {
      Default: (
        <>
          Please check that all information in the EPD copies is correct and set EPD publication and revision dates under
          Step 4.
        </>
      ),
    },
    'Approved by Licensee Admin': {
      Default: <>Please check that all information in the EPD copies is correct and approve EPD copies for publication.</>,
    },
    'Declined copy by Licensee Admin': { Default: <>No actions needed.</> },
    'Declined publication': { Default: <>No actions needed.</> },
  };

  const explanationMessagesLicenseeAdmin = {
    Draft: {
      Default: (
        <>
          With copy EPD functionality you can easily create similar EPDs based on EPD source template.{' '}
          <HyperLink onClick={() => helpButtonRef?.current?.click()}>Read full instruction here</HyperLink>
        </>
      ),
    },
    Reserved: {
      Default: (
        <>
          Please fill-in the information under Step 2 - Product information. Once you filled out all mandatory data you can
          start the verification process by clicking the button "Start verification".
        </>
      ),
    },
    'Sent for verification': {
      Default: <>EPD verification process in progress now. Please wait for EPD verifier's confirmation.</>,
    },
    Verified: {
      Default: (
        <>
          Your EPDs have been verified. If a certain EPD was declined after the verification - you can update, delete or
          unlink these EPDs from the group.You will be able to access the unlinked EPD from your dashboard. If you update
          declined EPDs you will be able to send the copy batch EPDs for verification. If you unlink or delete declined EPD
          from a copy batch you will be able to send the rest of approved EPDs for publishing.
        </>
      ),
    },
    'Sent for publication': {
      Default: (
        <>
          No actions needed.
        </>
      ),
    },
    'Approved by Licensee Admin': {
      Default: <>Please check that all information in the EPD copies is correct and approve EPD copies for publication.</>,
    },
    'Declined copy by Licensee Admin': { Default: <>No actions needed.</> },
    'Declined publication': { Default: <>No actions needed.</> },
  };

  const explanationMessagesVerifier = {
    Draft: {
      Default: (
        <>
          With copy EPD functionality you can easily create similar EPDs based on EPD source template.{' '}
          <HyperLink onClick={() => helpButtonRef?.current?.click()}>Read full instruction here</HyperLink>
        </>
      ),
    },
    Reserved: {
      Default: (
        <>
          Please fill-in the information under Step 2 - Product information. Once you filled out all mandatory data you can
          start the verification process by clicking the button "Start verification".
        </>
      ),
    },
    'Sent for verification': {
      3: (
        <>
          Please upload verification report for the each EPD and click “Approve” button. If certain EPDs doesn’t meet
          verification standards - click “Decline” button.
          <br />
          <br />
          Once all EPDs will be review please click “Confirm verification” button to confirm that verification process was
          finished.
        </>
      ),
      Default: (
        <>
          Please check that the uploaded EPD and filled information in Steps 1 and 2 correspond with the verified information
          in the LCA report and EPD document.
          <br /> After the review, please upload the verification report and click “Approve” button.
          <br /> If certain EPDs cannot be approved please click “Decline button”.
          <br /> When you finish with EPDs review and all EPDs are marked with correspond review status please click “Confirm
          verification” button.
        </>
      ),
    },
    Verified: { Default: <>EPD review has been complete. No actions needed.</> },
    'Sent for publication': { Default: <>EPD review has been complete. No actions needed.</> },
    'Approved by Licensee Admin': { Default: <>EPD review has been complete. No actions needed.</> },
    'Declined copy by Licensee Admin': { Default: <>EPD review has been complete. No actions needed.</> },
    'Declined publication': { Default: <>EPD review has been complete. No actions needed.</> },
  };

  const onGoBack = () => {
    if (copyGroupId === undefined) {
      history.push(EpdLinks.dashboard());
    } else {
      confirmQuestion({
        caption: 'Do you want to leave the editing process?',
        description: (
          <div>
            All entered data will be saved.
            <p style={{ marginTop: '0.5rem' }}>You can access your EPD copy group from the dashboard.</p>
          </div>
        ),
        acceptLabel: 'Leave',
        rejectLabel: 'Cancel',
        onConfirm: () => {
          history.push(EpdLinks.dashboard());
        },
        closable: false,
        contentStyle: { paddingTop: '2.5rem' },
      });
    }
  };

  const statusValues: Record<EPDCopyGroupStatus, number> = {
    [EPDCopyGroupStatus.Draft]: 0,
    [EPDCopyGroupStatus.Reserved]: 2,
    [EPDCopyGroupStatus.SentForVerification]: 3,
    [EPDCopyGroupStatus.Verified]: 4,
    [EPDCopyGroupStatus.SentForPublication]: 5,
    [EPDCopyGroupStatus.ApprovedByLicenseeAdmin]: 5,
    [EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin]: 5,
    [EPDCopyGroupStatus.DeclinedPublication]: 5,
  };

  const getStepStatus = (stepNo: number) => {
    if (stepNo === activeStepIndex) {
      return StepStatus.Active;
    }
    if (stepNo < statusValues[groupData?.status || EPDCopyGroupStatus.Draft]) {
      return StepStatus.Done;
    }
    return StepStatus.Todo;
  };

  const getExplanationMessage = () => {
    if (
      groupData?.verificationType === VerificationType.EPDProcessCertification &&
      groupData?.status === EPDCopyGroupStatus.Reserved
    ) {
      return groupData?.epds?.every((item: EPDModel) => item.epdState?.isMandatoryComplete)
        ? 'Please upload your process certificate and verification report for each of the EPDs. Once all verification reports will be uploaded please accept service agreement in Step 4 and click “Send to publish” button.'
        : 'Please fill-in the information under Step 2 - Product information. Once you filled out all mandatory data you can start the process certification verification under Step 3 - EPD verification.';
    }

    const messages =
      groupData.verifierId === AuthService.getUser()?.id
        ? explanationMessagesVerifier[groupData.status || EPDCopyGroupStatus.Draft]
        : AuthService.isAdmin() 
        ? explanationMessagesAdmin[groupData.status || EPDCopyGroupStatus.Draft]
        : AuthService.isLicenseeAdmin()
        ? explanationMessagesLicenseeAdmin[groupData.status || EPDCopyGroupStatus.Draft]
        : explanationMessages[groupData.status || EPDCopyGroupStatus.Draft];

    const result = Object.getOwnPropertyDescriptor(messages, activeStepIndex)?.value || messages['Default'];

    return result;
  };

  return (
    <Container>
      <BlockUI
        blocked={isUiBlocked}
        fullScreen
        template={<ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="4" />}
      />
      {isUpdatingEpd && <Spinner />}
      <ConfirmDialog />
      <MainViewFull>
        <WizardHeaderGrid>
          <TitleAndBack
            title={groupData?.name ?? 'New EPD copy'}
            status={groupData?.status ?? EPDCopyGroupStatus.Draft}
            backTo={'Back to dashboard'}
            onBack={onGoBack}
          />
          <WizardHeaderButtonBox>
            <ActionSecondaryButton
              actionState={groupData?.groupState?.delete}
              label="Delete group"
              onClick={confirmDeleteGroup}
              outlined
              type="reset"
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Start verification"
              onClick={onSendToVerification}
              actionState={groupData?.groupState?.startVerification}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Cancel verification"
              onClick={confirmCancelVerification}
              actionState={groupData?.groupState?.cancelVerification}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Confirm verification"
              onClick={onConfirmVerification}
              actionState={groupData?.groupState?.confirmVerification}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Send to publish"
              onClick={onSendToPublish}
              actionState={groupData?.groupState?.sendToPublish}
            />
            <ActionSecondaryButton
              style={{ marginLeft: '16px' }}
              label="Decline EPD Copy"
              onClick={confirmDeclinePublicationByLicenseeAdmin}
              actionState={groupData?.groupState?.declineCopyByLicenseeAdmin}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Approve EPD Copy"
              onClick={onApproveCopyByLicenseeAdmin}
              actionState={groupData?.groupState?.approvedByLicenseeAdmin}
            />
            <ActionSecondaryButton
              style={{ marginLeft: '16px' }}
              label="Decline EPD Copy"
              onClick={confirmCancelPublication}
              actionState={groupData?.groupState?.declineCopy}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Publish EPD Copy"
              onClick={onPublish}
              actionState={groupData?.groupState?.publish}
            />
          </WizardHeaderButtonBox>
        </WizardHeaderGrid>

        {(groupData.status === EPDCopyGroupStatus.DeclinedPublication ||
          groupData.status === EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin) && (
          <ErrorTextBox>
            {groupData.declineReason ||
              'EPD copies publication were cancelled. Please contact the secretariat for further information.'}
          </ErrorTextBox>
        )}

        {isExplanationVisible && (
          <ExplanationText onCloseButtonClick={() => setIsExplanationVisible(false)}>
            {getExplanationMessage()}
          </ExplanationText>
        )}
        <FormPanel style={{ padding: '12px 30px' }}>
          {steps.map((tab, index) => (
            <React.Fragment key={index}>
              {index > 0 && <img src={chevronRight} alt=">" />}
              <Step key={index} onClick={() => setActiveStepIndex(index + 1)}>
                <StepBadge
                  stepNumber={index + 1}
                  isSelected={index + 1 === activeStepIndex}
                  status={getStepStatus(index + 1)}
                />
                <div>{tab.label}</div>
              </Step>
            </React.Fragment>
          ))}
        </FormPanel>

        <Formik initialValues={initialValues} validationSchema={null} onSubmit={(values) => {}}>
          {() => <React.Fragment key={groupData?.name}>{steps[activeStepIndex - 1].render()}</React.Fragment>}
        </Formik>
        <Help helpButtonRef={helpButtonRef} backLabel="Go back" />
      </MainViewFull>
    </Container>
  );
};

export default withGreyBackground(CopyEpdWizard);
