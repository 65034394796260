import { StyledPrimeCheckbox } from 'components/v2/form/styled';
import SmartLabel from 'components/v2/labels/SmartLabel';
import { useField } from 'formik';
import { ErrorMessage } from 'styles/Styles.styled';

const PrimeCheckbox = ({
  label,
  tooltip,
  tooltipPosition,
  required,
  labelStyle,
  inputStyle,
  handlePostChangeAction,
  ...props
}: any) => {
  const [field, meta, helpers] = useField(props);

  // Extract the necessary values from formik's field props
  const { name, value, onBlur, onChange } = field;

  const onChangeInner = (event: any) => {
    onChange(event);
    if (handlePostChangeAction) {
      handlePostChangeAction(event);
    }
  };

  return (
    <>
      <StyledPrimeCheckbox
        {...field}
        id={props.id || name}
        style={inputStyle}
        onBlur={(event) => {
          helpers.setTouched(true);
          onBlur(event);
        }}
        onChange={onChangeInner}
        {...props}
        checked={value}
      />
      {label && (
        <SmartLabel
          label={label}
          required={required}
          labelStyle={{ textTransform: 'none', ...labelStyle }}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={field?.value}
        />
      )}
      {meta.touched && meta.error && !props.supressErrors ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    </>
  );
};

export default PrimeCheckbox;
