import styled from 'styled-components';

export const HelpBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  padding: 0.5rem 1rem;
  gap: 10px;
  background-color: ${(props) => props.theme.colors.elementsFilledInput};
  border-radius: 4px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  ${(props) => props.theme.typography.bodyBody14Regular};
`;

export const FileHelpBox = styled(HelpBox)`
  background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
  flex-direction: row;
  padding: 14px 16px;
  justify-content: space-between;
  align-items: center;
`;

export const EmptyFileHelpBox = styled(FileHelpBox)`
  background-color: ${(props) => props.theme.colors.elementsFilledInput};
  justify-content: start;
`;
