import ConfirmModal from 'components/modals/ConfirmModal';
import { REMOVEFILE } from 'constants/constants';
import removeIcon from 'images/icons/svg/error.svg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileModelPlus } from 'services/EpdClient';
import FileService from 'services/FileService';
import styled from 'styled-components';
import { ButtonSmall } from 'styles/Styles.styled';

import { UploadButton } from './WizardStep2';

interface ImageUploadProps {
  name: string;
  images?: FileModelPlus[];
  onUpload: (e: any) => any;
  onReorder: (imgIds: string[]) => any;
  onChangeEpd: any;
  disabled: boolean;
}

export const ImagesUpload: React.FunctionComponent<ImageUploadProps> = ({
  name,
  images,
  onUpload,
  onReorder,
  onChangeEpd,
  disabled,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [dragId, setDragId] = useState<any>(null);
  const [previewImages, setPreviewImages] = useState<PImg[] | undefined>(undefined);

  interface PImg {
    id: number;
    imgId?: string;
    imgName?: string | undefined;
    epdFileId?: string;
  }

  useEffect(() => {
    setPreviewImages(
      images?.map((img, index) => {
        return { id: index + 1, imgId: img.id, imgName: img.name, epdFileId: img.epdFileId } as PImg;
      })
    );
  }, [images]);

  const closeDeleteModal = () => {
    setIsModalOpen(false);
  };

  const deleteImage = (img: any) => {
    setIsModalOpen(true);
    setSelectedImage(img);
  };

  const handleOver = (ev: any) => {
    ev.preventDefault();
  };

  const handleDrag = (ev: any) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev: any) => {
    ev.preventDefault();
    const dragImage = previewImages?.find((image) => image.id.toString() === dragId);
    const dropImage = previewImages?.find((image) => image.id.toString() === ev.currentTarget.id);
    if (dragImage && dropImage) {
      const arr = moveItem(dragImage?.id - 1, dropImage?.id - 1);
      if (arr && arr.length > 1) {
        onReorder(arr.map((i) => i.epdFileId || ''));
      }
      setPreviewImages(arr);
    }
  };

  const moveItem = (from: number, to: number) => {
    const f = previewImages?.splice(from, 1)[0];
    if (f) {
      previewImages?.splice(to, 0, f);
    }

    return previewImages?.map((img, index) => {
      return { id: index + 1, imgId: img.imgId, imgName: img.imgName, epdFileId: img.epdFileId } as PImg;
    });
  };

  const getImageBox = (img: any) => {
    return (
      <ImageBox
        key={img.id}
        id={img.id}
        draggable
        onDragOver={(e) => handleOver(e)}
        onDragStart={(e) => handleDrag(e)}
        onDrop={(e) => handleDrop(e)}
      >
        <ImageBoxImage>{img.imgId && <img alt={`Custom ${name}`} src={FileService.getImageUrl(img.imgId)} />}</ImageBoxImage>
        <ImageNo>{img.id}</ImageNo>
        {!disabled && <ImageRemove >
          <img src={removeIcon} style={{ height: '1rem' }} alt="Remove" onClick={() => deleteImage(img)} />
        </ImageRemove>}
      </ImageBox>
    );
  };

  return (
    <div>
      <UploadButton>
        <ButtonSmall disabled={disabled}>
          {t('upload')} {name}
        </ButtonSmall>
        <input type="file" name={name} onChange={onUpload} disabled={disabled} />
      </UploadButton>
      <div style={{ display: 'flex', marginBottom: '1rem', flexWrap: 'wrap' }}>
        {previewImages && previewImages.map(getImageBox)}
      </div>
      {isModalOpen && (
        <ConfirmModal
          description={t('confirmModal.deleteImage')}
          confirmButtonText={t('delete')}
          cancelButtonText={t('cancel')}
          onClickConfirm={() => {
            onChangeEpd(REMOVEFILE, selectedImage?.imgId);
            closeDeleteModal();
          }}
          onClickCancel={() => closeDeleteModal()}
        />
      )}
    </div>
  );
};

const ImageBox = styled.div`
  position: relative;
  flex: 0 0 46%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 1rem 1rem 0 0;
  background-color: ${(props) => props.theme.colors.lightGreen};
`;

const ImageBoxImage = styled.div`
  flex: 0 0 auto;
  align-self: center;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 150px;
    max-height: 150px;
  }
`;

const ImageRemove = styled.div`
  margin: 0.5rem;
  position: absolute;
  right: 0;
  cursor: pointer;
`;

const ImageNo = styled.div`
  margin: 0.5rem;
  position: absolute;
  left: 0;
`;
