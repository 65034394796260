import 'primeflex/primeflex.css';

import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';

const CollectionGuide = () => {
  const template = (options: PanelHeaderTemplateOptions) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} justify-content-start border-none `;
    const titleClassName = `${options.titleClassName} ml-2`;

    return (
      <div className={className} style={{ backgroundColor: 'rgba(180, 208, 182, 0.3)' }}>
        <span className={titleClassName}>EPD Collection guide</span>
        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
          <span className={toggleIcon}></span>
        </button>
      </div>
    );
  };

  return (
    <Panel className="border-none mb-3" headerTemplate={template} toggleable collapsed>
      <p className="m-0">Collections help you to organise published EPD documents in a hierarchical and structured way.</p>
      <p className="m-0">
        Classify your EPDs under collection to make them easy reachable for the customers in our EPD library!
      </p>
      <p>EPD Collection is available on EPD website under EPD Library section.</p>
      <b>How to create collection:</b>
      <p className="m-0">1. Fill the required information below.</p>
      <p className="m-0">
        2. Click button “Save collection”. AWhen you have created a collection, you will see it on the collection dashboard.
      </p>

      <p className="mb-0">
        <b>How to publish collection on EPD website:</b>
      </p>
      <p className="m-0">1. On the collection details page. Click “publish collection” in the right corner.</p>
      <p className="m-0">
        2. On the collection dashboard: click “3 dots” icon on the collection card. Click “Publish collection”.
      </p>

      <p className="mb-0">
        <b>How to update a collection:</b>
      </p>
      <p className="m-0">
        1. Open the collection and press the edit button. Do your changes and press the button "Save collection".
      </p>
      <p className="m-0">2. If you want to see the updated version you need to publish it again.</p>

      <p>Please note, only published EPDs will be visible under collection on EPD website.</p>
    </Panel>
  );
};

export default CollectionGuide;
