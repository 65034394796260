import React, { useEffect } from 'react';

export const withGreyBackground = (WrappedComponent: any) => {
  const WithGreyBackground = (props: any) => {
    useEffect(() => {
      document.body.classList.add('new-ui');
      return () => {
        document.body.classList.remove('new-ui');
      };
    }, []);

    return <WrappedComponent {...props} />;
  };

  return WithGreyBackground;
};
