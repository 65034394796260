import ConfirmationAreaLCAPractitioner from 'components/v2/copy/ConfirmationAreaLCAPractitioner';
import ConfirmationAreaOwner from 'components/v2/copy/ConfirmationAreaOwner';
import ConsentCheckBox from 'components/v2/copy/ConsentCheckBox';
import DatePicker from 'components/v2/copy/DatePicker';
import InputFormField from 'components/v2/form/InputFormField';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AuthService from 'services/AuthService';
import { ActionState, EPDCopyGroupStatus } from 'services/EpdClient';
import { EpdCopyGroupWizardModel, VerificationType } from 'services/EpdClient';
import styled from 'styled-components';
import { StyledForm } from 'styles/Styles.styled';
import { FieldPanel, FieldPanelFullWidth, FieldPanelInput } from 'styles/v2/Styles.styled';
import { formatDate } from 'util/utils';

const WizardStep4: React.FunctionComponent<{
  groupData?: EpdCopyGroupWizardModel;
  onChangeEpdGroup: any;
}> = ({ groupData, onChangeEpdGroup }) => {
  const onChangeGroupInternal = (id: string, name: string, value: any) => {
    onChangeEpdGroup(id, name, value);
  };

  const publicationDate = groupData?.publicationDate ? new Date(groupData.publicationDate as Date) : undefined;
  const revisionDate = groupData?.revisionDate ? new Date(groupData.revisionDate as Date) : undefined;
  const validityDate = groupData?.validityDate ? new Date(groupData.validityDate as Date) : undefined;
  const isChecked = groupData?.isAgreementAccepted;
  const isVerified: boolean = groupData?.status === EPDCopyGroupStatus.Verified;
  const isSentForPublish: boolean =
    groupData?.status === EPDCopyGroupStatus.SentForPublication ||
    groupData?.status === EPDCopyGroupStatus.ApprovedByLicenseeAdmin;
  const isDeclined: boolean =
    groupData?.status === EPDCopyGroupStatus.DeclinedPublication ||
    groupData?.status === EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin;
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  const isOwner = AuthService.isCompanyOwner(company?.id as string);
  const companyEmail = company?.email || groupData?.developersSource?.find((dev) => dev.isAccountManager)?.userEmail;
  const epdOwner: string =
    company?.name && companyEmail ? `${company?.name} | ${companyEmail}` : company?.name || companyEmail || '';
  const lcaPractitionerState =
    groupData?.groupState?.lcaPractitionerState === ActionState.Enabled && !isOwner && !isSentForPublish
      ? ActionState.Enabled
      : ActionState.Hidden;
  return (
    <>
      <StyledForm>
        <FieldPanel state={AuthService.isAdmin() ? ActionState.Enabled : ActionState.Hidden}>
          <DatePicker
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const inputDate = new Date(e.target.value);
              const utcDate = new Date(Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate()));
              e.target.value !== formatDate(groupData?.publicationDate) &&
                onChangeGroupInternal(groupData?.id!, 'publicationDate', utcDate);
            }}
            label={'Publication date'}
            initialDate={publicationDate}
            tooltip={t('epdWizard.wizardStep4.tooltips.publicationDate')}
            required
          />
          <DatePicker
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const inputDate = new Date(e.target.value);
              const utcDate = new Date(Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate()));
              e.target.value !== formatDate(groupData?.validityDate) &&
                onChangeGroupInternal(groupData?.id!, 'validityDate', utcDate);
            }}
            label={'Validity date'}
            initialDate={validityDate}
            tooltip={t('epdWizard.wizardStep4.tooltips.validUntil')}
            required
          />
          {/* <DatePicker
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.value !== formatDate(groupData?.revisionDate) &&
                onChangeGroupInternal(groupData?.id!, 'revisionDate', new Date(e.target.value));
            }}
            label={'Revision date'}
            initialDate={revisionDate}
            tooltip={t('epdWizard.wizardStep4.tooltips.versionDate')}
            required
          /> */}
        </FieldPanel>
        <FieldPanelFullWidth>
          <FieldPanelInput>
            <InputFormField label={'EPD owner'} value={epdOwner} name="epdOwner" disabled />
          </FieldPanelInput>
          <FieldPanelInput>
            <InputFormField
              label={'Country'}
              value={company?.address?.country || ''}
              name="country"
              tooltip="The companies country of origin"
              disabled
            />
          </FieldPanelInput>
          <FieldPanelInput>
            <InputFormField
              label={'Time stamp'}
              value={formatDate(groupData?.modifiedAt)}
              name="timeStamp"
              tooltip={t('epdWizard.wizardStep4.tooltips.timeStamp')}
              disabled
            />
          </FieldPanelInput>
          <FieldPanelInput>
            <InputFormField
              label={'Copyright'}
              value={t('epdWizard.wizardStep4.properties.unprotected')}
              name="copyright"
              tooltip="Indicates if there are copyrights on the EPD"
              disabled
            />
          </FieldPanelInput>
        </FieldPanelFullWidth>
        {groupData?.groupState?.agreementAccepted != ActionState.Hidden && (
          <>
            {' '}
            <FieldPanelFullWidth
              state={
                (groupData?.verificationType == VerificationType.EPDVerification && (isVerified || isDeclined) && isOwner) ||
                (groupData?.verificationType == VerificationType.EPDProcessCertification &&
                  (groupData?.groupState?.sendToPublish == ActionState.Disabled ||
                    groupData?.groupState?.sendToPublish == ActionState.Enabled))
                  ? ActionState.Enabled
                  : ActionState.Hidden
              }
            >
              <ConfirmationAreaOwner tooltip labelStyle={labelStyle} label={'Confirmation for publication'} />
              <CheckBoxPanel>
                <ConsentCheckBox
                  name="agree"
                  inputStyle={{ margin: '0 0.5rem 0 0' }}
                  checked={isChecked}
                  disable={groupData?.groupState?.agreementAccepted == ActionState.Disabled}
                  onCheck={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.checked !== groupData?.isAgreementAccepted &&
                      onChangeGroupInternal(groupData?.id!, 'isAgreementAccepted', e.target.checked);
                  }}
                  label={'I have read and accept the service agreement'}
                />
              </CheckBoxPanel>
            </FieldPanelFullWidth>
            <FieldPanelFullWidth
              state={
                groupData?.verificationType == VerificationType.EPDVerification &&
                !isVerified &&
                isOwner &&
                !isSentForPublish &&
                !isDeclined
                  ? ActionState.Enabled
                  : ActionState.Hidden
              }
            >
              <FieldPanelInput>
                <InputFormField
                  label={'Confirmation for publication'}
                  labelStyle={labelStyle}
                  value="Once the verification process is finished, 
              please accept service agreement in step 4 and send to publish your EPD copies."
                  name="confirmation"
                  disabled
                />
              </FieldPanelInput>
            </FieldPanelFullWidth>
            <FieldPanelFullWidth state={lcaPractitionerState}>
              <FieldPanelInput>
                <ConfirmationAreaLCAPractitioner labelStyle={labelStyle} label={'Confirmation for publication'} />
              </FieldPanelInput>
            </FieldPanelFullWidth>
          </>
        )}
      </StyledForm>
    </>
  );
};

const labelStyle = {
  textTransform: 'uppercase',
  width: 'fit-content',
  fontWeight: '600',
};

const CheckBoxPanel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem;
  width: 100%;
  background-color: rgba(231, 81, 19, 0.1);
  border-radius: 2px;
`;

export default WizardStep4;
