import SmartLabel from '../labels/SmartLabel';
import { ProcessStatus } from 'constants/constants';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PCRModel, ProductCategoryModel } from 'services/EpdClient';
import FileService from 'services/FileService';
import PcrService from 'services/PcrService';
import styled from 'styled-components';
import { Option } from 'types/types';

import PcrSelectorDetails from './PcrSelectorDetails';
import PcrSelectorDialog from './PcrSelectorDialog';
import PcrSelectorFilesDialog from './PcrSelectorFilesDialog';

interface PcrProps {
  label?: string | undefined;
  tooltip?: string | undefined;
  tooltipPosition?: any | undefined;
  pcr: PCRModel | undefined;
  disabled: boolean | undefined;
  required: boolean | undefined;
  onChangePcr: (pcr: PCRModel | undefined) => void;
}

const PcrSelector: React.FunctionComponent<PcrProps> = ({
  label,
  tooltip,
  tooltipPosition,
  pcr,
  disabled,
  required,
  onChangePcr,
}) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [pcrList, setPcrList] = useState<PCRModel[]>([]);
  const [productCategory, setProductCategory] = useState<Option | undefined>();
  const [selectedPcr, setSelectedPcr] = useState<PCRModel | undefined>(pcr);
  const [productCategoryList, setProductCategoryList] = useState<ProductCategoryModel[]>([]);
  const [showFiles, setShowFiles] = useState<boolean>(false);
  const [isEditOpened, setIsEditOpened] = useState<boolean>(false);

  const findPcr = async (val: string, catId?: string, isDisabled?: boolean) => {
    setStatus(ProcessStatus.Fetching);
    const res = await PcrService.findPcr(val || '', catId, isDisabled);
    setPcrList(res);
    setStatus(ProcessStatus.Success);
  };

  const debounceLoadData = useCallback(_.debounce(findPcr, 500), []);

  React.useEffect(() => {
    const fetchPcr = async () => {
      if (!productCategory) {
        setPcrList([]);
        return;
      }

      debounceLoadData('', productCategory?.value, false);
    };
    fetchPcr();
  }, [productCategory, debounceLoadData]);

  React.useEffect(() => {
    const fetchCats = async () => {
      const res = await PcrService.getAllCategories();
      setProductCategoryList(res);
    };

    fetchCats();
  }, []);

  const selectPcr = (selectedPcr: PCRModel | undefined) => {
    onChangePcr(selectedPcr);
  };

  const productCategoryListOptions: any = () => {
    const list = productCategoryList.map((item) => {
      return {
        value: item.id,
        label: item.title,
      };
    });

    return list;
  };

  const handleCloseDialog = () => {
    setIsEditOpened(false);
    setStatus(ProcessStatus.None);
    setPcrList([]);
    setProductCategory(undefined);
    setSelectedPcr(undefined);
  };

  const handleSelectPcr = () => {
    selectPcr(selectedPcr);
    handleCloseDialog();
  };

  const handleDownloadSelectedPcrFiles = (pcr: PCRModel | undefined) => {
    if (pcr?.documents && pcr.documents?.length > 1) {
      setShowFiles(true);
    } else if (pcr?.documents && pcr.documents?.length === 1) {
      window.open(FileService.getImageUrl(pcr.documents[0].id || ''), '_blank', 'noreferrer');
    }
  };

  return (
    <>
      {label && (
        <SmartLabel
          label={label}
          required={required}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={pcr !== undefined}
        />
      )}
      <div style={{ flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'column' }}>
        <PcrInputGroup className="p-inputgroup" disabled={disabled}>
          <InputTextStyled name="pcr" value={pcr ? pcr.fullName : ''} readOnly disabled={disabled} />
          <PcrInputAddon className="p-inputgroup-addon" disabled={disabled}>
            {pcr && !disabled && (
              <i
                className="pi pi-times"
                onClick={() => {
                  selectPcr(undefined);
                }}
              />
            )}
          </PcrInputAddon>
          <PcrInputAddon className="p-inputgroup-addon" disabled={disabled}>
            {pcr && !disabled && (
              <i
                className="pi pi-download"
                onClick={() => {
                  handleDownloadSelectedPcrFiles(pcr);
                }}
              />
            )}
          </PcrInputAddon>
        </PcrInputGroup>
        {!disabled && (
          <AddButtonBox>
            <Button
              type="button"
              label="+ Add PCR"
              text
              onClick={() => setIsEditOpened(true)}
              disabled={!!pcr}
            />
          </AddButtonBox>
        )}

        <PcrSelectorDialog
          header="Add PCR"
          isOpened={isEditOpened}
          onHide={handleCloseDialog}
          onChange={handleSelectPcr}
          contentStyle={pcrList?.length > 0 ? {} : { overflowY: 'initial' }}
        >
          <PcrSelectorDetails
            productCategoryList={productCategoryListOptions()}
            productCategory={productCategory}
            pcrList={pcrList}
            pcr={selectedPcr}
            onProductCategorySelect={setProductCategory}
            onPcrSelect={setSelectedPcr}
            status={status}
          />
        </PcrSelectorDialog>

        <PcrSelectorFilesDialog
          header={pcr?.fullName ?? ''}
          isOpened={showFiles}
          onHide={() => {
            setShowFiles(false);
          }}
        >
          <PcrListFileTitle>Here you can download PCR related documents.</PcrListFileTitle>
          {pcr?.documents?.map(
            (file, index) =>
              file && (
                <PcrListFileItem key={index}>
                  <span>{file.name || t('epdWizard.wizardStep1.pcrMenu.placeholderImage')}</span>
                  <PcrListFileDownloadIcon
                    className="pi pi-download"
                    onClick={() => {
                      window.open(FileService.getImageUrl(file.id || ''), '_blank', 'noreferrer');
                    }}
                  />
                </PcrListFileItem>
              )
          )}
        </PcrSelectorFilesDialog>
      </div>
    </>
  );
};

const PcrListFileDownloadIcon = styled.i`
  align-self: center;
  font-weight: 700;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primaryOrange};
`;

const PcrListFileTitle = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: ${(props) => props.theme.colors.elementsFilledInput};
  border-radius: 4px;
`;

const PcrListFileItem = styled.div`
  padding: 1rem;
  margin-bottom: 0.5rem;
  background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  span {
    width: 80%;
  }
`;

const AddButtonBox = styled.div`
  width: 100%;
  border-radius: 4px;
  border: none;
  justify-content: start;
  display: flex;
  max-height: 70px;

  .p-button.p-button-text {
    padding: 0;
    background-color: transparent;
    border: none;
    margin-top: 0.25rem;
    ${(props) => props.theme.fonts.standard};
  }
  .p-button.p-button-text:hover {
    background-color: transparent;
  }
  .p-button.p-button-text:disabled {
    color: #757575;
  }
`;

const InputTextStyled = styled(InputText)`
  padding: 1rem;
  border: 1px solid
    ${(props) => (props.disabled ? props.theme.colors.elementsFilledInput : props.theme.colors.elementsInputBorder)};
  border-radius: 2px;
  margin: 0;
  background-color: ${(props) => props.theme.colors.primaryWhite};

  &.p-component.p-disabled {
    background-color: ${(props) => props.theme.colors.elementsFilledInput};
  }

  &:focus {
    border-color: ${(props) => (props.disabled ? props.theme.colors.elementsInputBorder : props.theme.colors.primaryOrange)};
  }

  &:focus-visible {
    outline: 2px solid
      ${(props) => (props.disabled ? props.theme.colors.elementsInputBorder : props.theme.colors.primaryOrange)};
  }

  &:hover {
    border-color: ${(props) => (props.disabled ? props.theme.colors.elementsInputBorder : props.theme.colors.primaryOrange)};
  }

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 44px;
`;

const PcrInputAddon = styled.span<{ disabled?: boolean | undefined }>`
  border: none;
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.elementsFilledInput : props.theme.colors.primaryWhite};
  cursor: auto;
  padding: 0.5rem 1rem 0.5rem 0rem;
  min-width: 0;
  .pi {
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 700;
  }
`;

const PcrInputGroup = styled.div<{ disabled: boolean | undefined }>`
  border: 1px solid ${(props) => (props.disabled ? 'transparent' : props.theme.colors.elementsInputBorder)};
  &:disabled {
    border: none;
  }
  &:hover {
    border-color: ${(props) => (props.disabled ? 'transparent' : props.theme.colors.primaryOrange)};
  }
  .p-inputgroup-addon:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  ${InputTextStyled} {
    border: none;
    cursor: auto;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
`;

export default PcrSelector;
