import styled from 'styled-components';

export const DialogContainer = styled.div`
  width: 525px;
  min-height: 214;
  text-align: center;
  padding: 10px 2rem 1rem 2rem;
  margin: 0;
`;

export const Description = styled.span`
  ${(props) => props.theme.typography.bodyBody14Regular}
  color: ${(props) => props.theme.colors.primaryBlack70};
`;
