import { editAdminSelectStyles } from 'components/form/EditAdminSelectStyles';
import LargeRadiusTextInput from 'components/form/LargeRadiusTextInput';
import { ProcessStatus } from 'constants/constants';
import { Form, Formik } from 'formik';
import { FixedLabel } from 'pages/pcrWizard/PcrWizard';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AdminService from 'services/admin/AdminService';
import styled from 'styled-components';
import { PoorMansError } from 'util/utils';

import OverlaySpinner from '../../../../components/spinners/OverlaySpinner';
import { AdminBox, ButtonSmall, Container, EditFull, H2, H3, StyledReactSelect } from '../../../../styles/Styles.styled';
import { IlcdEpdMappingModel, Option, environdecTypeEnum, ilcdTypeEnum } from './types';

const IlcdEpdMapping: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [mapping, setMapping] = useState<IlcdEpdMappingModel>();
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);

  useEffect(() => {
    AdminService.GetILCDRelation(id).then((relation) => {
      AdminService.getIlcdEpdMapping(relation.id).then((mapping) => {
        setMapping({
          ...mapping,
          environdecType: { label: mapping.environdecType },
          ilcdPlusEPDType: { label: mapping.ilcdPlusEPDType },
        });
      });
    });
  }, [id]);

  const reloadMapping = async () => {
    try {
      let result = await AdminService.getIlcdEpdMapping(mapping?.id || '');
      setMapping({
        ...result,
        environdecType: { label: result.environdecType },
        ilcdPlusEPDType: { label: result.ilcdPlusEPDType },
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toast.error(PoorMansError(error), {
        position: 'top-center',
      });
    }
  };

  const onSaveChanges = async (data: IlcdEpdMappingModel) => {
    try {
      setStatus(ProcessStatus.Fetching);
      const result = await AdminService.updateIlcdEpdMapping(data);
      setMapping({
        ...result,
        environdecType: { label: result.environdecType },
        ilcdPlusEPDType: { label: result.ilcdPlusEPDType },
      });
      setStatus(ProcessStatus.Success);
      toast.success(t('integration.ilcdEpd.successUpdate') as String, {
        position: 'top-center',
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toast.error(PoorMansError(error), {
        position: 'top-center',
      });
    }
  };

  const initialValues = {
    ilcduuid: '',
    epdVersionId: '',
    epdVersionDate: '',
    environdecObjectName: '',
    ilcdVersion: '',
    ilcdVersionDate: '',
    publishedToEcoplatform: '',
    environdecType: null,
    ilcdPlusEPDType: null,
  };

  const handleUpload = async (values: any) => {
    try {
      setStatus(ProcessStatus.Fetching);
      await AdminService.UploadIlcdEpdDataArchive(mapping?.id || '').then((x) => reloadMapping());
      setStatus(ProcessStatus.Success);
      toast.success(t('integration.ilcdEpd.successUpload') as String, {
        position: 'top-center',
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toast.error(PoorMansError(error), {
        position: 'top-center',
      });
    }
  };

  const handlePublish = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await AdminService.PublishIlcdEpd(mapping?.id || '').then(() => reloadMapping());
      setStatus(ProcessStatus.Success);
      toast.success(t('integration.ilcdEpd.successPublish') as String, {
        position: 'top-center',
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toast.error(PoorMansError(error), {
        position: 'top-center',
      });
    }
  };
  const handleUnpublish = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await AdminService.UnpublishIlcdEpd(mapping?.id || '').then(() => reloadMapping());
      setStatus(ProcessStatus.Success);
      toast.success(t('integration.ilcdEpd.successUnpublish') as String, {
        position: 'top-center',
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toast.error(PoorMansError(error), {
        position: 'top-center',
      });
    }
  };

  return (
    <Container>
      {status === ProcessStatus.Fetching && <OverlaySpinner></OverlaySpinner>}

      <EditFull>
        <H2>{t('integration.ilcdEpd.ilcdEpdMapping')}</H2>
        <AdminBox style={{ backgroundColor: '#FDFDFD' }}>
          <Formik initialValues={mapping || (initialValues as any)} enableReinitialize onSubmit={onSaveChanges}>
            {({ isSubmitting, values, errors }) => (
              <CustomForm>
                <EnvirondecContainer>
                  <H3>{t('integration.ilcdEpd.environdec')}</H3>
                  <InputContainer>
                    <FixedLabel>{t('integration.ilcdEpd.environdecType')}</FixedLabel>{' '}
                    <StyledReactSelect
                      defaultValue={initialValues.environdecType}
                      name="environdecType"
                      styles={editAdminSelectStyles}
                      options={environdecTypeEnum?.map((item) => {
                        return { value: item.value, label: item.text } as Option;
                      })}
                      isDisabled
                    />
                  </InputContainer>

                  <InputContainer>
                    <FixedLabel>{t('integration.ilcdEpd.environdecObject')} </FixedLabel>
                    <LargeRadiusTextInput disabled name="environdecObjectName" type="text" />
                  </InputContainer>

                  <InputContainer>
                    <FixedLabel>{t('integration.ilcdEpd.companyName')} </FixedLabel>
                    <LargeRadiusTextInput disabled name="companyName" type="text" />
                  </InputContainer>

                  <InputContainer>
                    <FixedLabel>{t('integration.ilcdEpd.version')}</FixedLabel>
                    <LargeRadiusTextInput disabled name="epdVersionId" type="text" />
                  </InputContainer>

                  <InputContainer>
                    <FixedLabel>{t('integration.ilcdEpd.updated')}</FixedLabel>{' '}
                    <LargeRadiusTextInput disabled name="epdVersionDate" type="text" />
                  </InputContainer>
                </EnvirondecContainer>

                <IlcdEpdContainer>
                  <H3>{t('integration.ilcdEpd.ilcdEpd')}</H3>

                  <InputContainer>
                    <FixedLabel>{t('integration.ilcdEpd.ilcdEpdType')}</FixedLabel>{' '}
                    <StyledReactSelect
                      defaultValue={initialValues.ilcdPlusEPDType}
                      name="ilcdPlusEPDType"
                      styles={editAdminSelectStyles}
                      options={ilcdTypeEnum?.map((item) => {
                        return { value: item.value, label: item.value } as Option;
                      })}
                      isDisabled
                    />
                  </InputContainer>

                  <InputContainer>
                    <FixedLabel>{t('integration.ilcdEpd.uuid')}</FixedLabel>
                    <LargeRadiusTextInput disabled name="ilcduuid" type="text" />
                  </InputContainer>

                  <InputContainer>
                    <FixedLabel>{t('integration.ilcdEpd.version')}</FixedLabel>
                    <LargeRadiusTextInput name="ilcdVersion" type="text" />
                  </InputContainer>

                  <InputContainer>
                    <FixedLabel>{t('integration.ilcdEpd.publishedToEcoplatform')}</FixedLabel>
                    <LargeRadiusTextInput disabled name="publishedToEcoplatform" type="text" />
                  </InputContainer>
                </IlcdEpdContainer>

                <ButtonsContainer>
                  <MappingButton type="button" onClick={() => handleUpload(values)}>
                    {t('integration.ilcdEpd.upload')}
                  </MappingButton>
                  <MappingButton type="submit">{t('save')}</MappingButton>
                  <MappingButton type="button" onClick={handlePublish}>
                    {t('integration.ilcdEpd.publish')}
                  </MappingButton>
                  <MappingButton type="button" onClick={handleUnpublish}>
                    {t('integration.ilcdEpd.unpublish')}
                  </MappingButton>
                </ButtonsContainer>
              </CustomForm>
            )}
          </Formik>

          <FixedLabel>Log</FixedLabel>
          <FixedTextAreaComponent value={mapping?.log} propertyName={'lcaSkills'} onChangeEpd={null} disabled={true} />
        </AdminBox>
      </EditFull>
    </Container>
  );
};

const CustomForm = styled(Form)`
  gap: 15px;
  display: grid;
  justify-content: space-between;
  align-items: left;
  grid-template-columns: auto;

  ${(props) => props.theme.media.desktop} {
    grid-template-columns: 1fr 1fr;
  }

  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const InputContainer = styled.div`
  min-height: 38px;
  grid-column-start: 1;
  grid-column-end: 1;
  margin-top: 10px;
`;

export const EnvirondecContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
`;

export const IlcdEpdContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  ${(props) => props.theme.media.tablet} {
    grid-column-start: 2;
    grid-column-end: 3;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 10px;
  gap: 20px;
  ${(props) => props.theme.media.desktop} {
    flex-direction: row;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  ${(props) => props.theme.media.tablet} {
    flex-direction: row;
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const MappingButton = styled(ButtonSmall)`
  padding: 0.5rem 1rem;
  border-radius: 3px;
`;
export const FixedTextArea = styled.textarea`
  min-width: 100%;
  height: 100px;
  margin: 0.5rem 0 1rem 0;
`;

const FixedTextAreaComponent: React.FunctionComponent<{
  value: any;
  propertyName: string;
  onChangeEpd: any;
  disabled: boolean;
}> = ({ value, propertyName, onChangeEpd, disabled }) => {
  return (
    <FixedTextArea
      defaultValue={value}
      style={{ minHeight: '50vh' }}
      disabled
      onBlur={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
        e.currentTarget.value !== value && onChangeEpd(propertyName, e.currentTarget.value)
      }
    />
  );
};

export default IlcdEpdMapping;
