import HTMLEditor from 'components/controls/WysiwygEditor';
import UploadFile from 'components/form/UploadFile';
import { REMOVEFILE } from 'constants/constants';
import downloadIcon from 'images/icons/svg/download.svg';
import trashIcon from 'images/icons/svg/garbage-2.svg';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { FileModel2, PCRModel2, PCRStatusSelection, ProductCategoryModel2 } from 'services/EpdClient';
import FileService from 'services/FileService';
import PcrService from 'services/PcrService';
import PreverifiedToolService from 'services/PreverifiedToolService';
import AdminPCRService from 'services/admin/AdminPcrService';
import styled from 'styled-components';
import {
  AdminBox,
  Container,
  EditFull,
  H2,
  InfoText,
  Label,
  LabelBox,
  LargeRadiusInput,
  LargeRadiusTextArea,
  SmallRadiusInput,
} from 'styles/Styles.styled';
import { Option } from 'types/types';
import { formatDate } from 'util/utils';

import { customToolbarPcr } from '../../../components/form/customToolbar';

const EditPcr: React.FunctionComponent = () => {
  const { pcrid } = useParams<{ pcrid: string }>();
  const [selectedPcr, setSelectedPcr] = useState<PCRModel2>();
  const status = Object.keys(PCRStatusSelection);
  const statusOptions = status.map((s: any) => {
    return { value: s, label: PCRStatusSelection[s as keyof typeof PCRStatusSelection] };
  });
  // const [preVerifiedTools, setPreVerifiedTools] = React.useState<PreverifiedToolModel2[]>([]);
  const [categories, setCategories] = useState<ProductCategoryModel2[]>([]);
  // const [documents, setDocuments] = useState<FileModel2[]>([]);
  // const [updatingPcr, setUpdatingPcr] = React.useState<ProcessStatus>(ProcessStatus.None);
  // const [error, setError] = useState<string | null>(null);
  // const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [preVerifiedToolsOptions, setPreVerifiedToolsOptions] = React.useState<any[]>([]);
  const [productCategoriesOptions, setProductCategoriesOptions] = React.useState<any[]>([]);

  const allDateValues = [
    'versionDate',
    'initiatedDate',
    'startOpenConsultationDate',
    'deadlineOpenConsultationDate',
    'reviewAndApprovalDate',
    'publicationDate',
    'showUpdateInfoUntil',
    'approvalDate',
    'deregistrationDate',
    'validUntil',
  ];

  const pcrEditorStyle = {
    border: '1px solid grey',
    padding: '0 1rem',
    fontSize: '14px',
    borderRadius: '3px',
  };

  const addPcrFile = async (pcrId: string, propertyName: string, file: File) => {
    const fileName = file.name;
    const fileType = file.type;
    const reader = new FileReader();
    reader.onload = async () => {
      const fileBlob = reader.result as ArrayBuffer;
      if (fileBlob) {
        const blob = new Blob([new Uint8Array(fileBlob)], { type: fileType });
        try {
          //setUpdatingPcr(ProcessStatus.Fetching);
          const result = await PcrService.addPcrFile(pcrId, propertyName, fileName, blob);
          setSelectedPcr(result);
          //setUpdatingPcr(ProcessStatus.Success);
          loadPcr();
        } catch {
          //setUpdatingPcr(ProcessStatus.Error);
        }
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const loadPcr = async () => {
    const fetchPcr = await AdminPCRService.findPcr(pcrid);
    setSelectedPcr(fetchPcr as any);
  };

  useEffect(() => {
    const loadCategories = async () => {
      const fetchCategories = await PcrService.getAllCategories();
      setCategories(fetchCategories as any);
      const options = fetchCategories.map((s: any) => {
        return { value: s.id, label: s.title };
      });
      setProductCategoriesOptions(options);
    };
    const loadPreVerifiedTools = async () => {
      const fetchPreVerifiedTools = await PreverifiedToolService.getPreVerifiedTool();
      // setPreVerifiedTools(fetchPreVerifiedTools as any);
      const options = fetchPreVerifiedTools.map((s: any) => {
        return { value: s.id, label: s.name };
      });
      setPreVerifiedToolsOptions(options);
    };
    loadPcr();
    loadCategories();
    loadPreVerifiedTools();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!selectedPcr || !status || !categories) {
    return <InfoText>Loading...</InfoText>;
  }

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];

    if (!file || !selectedPcr) {
      return null;
    }

    onChangePcr && onChangePcr(propertyName, file);
  };

  const onChangePcr = async (propertyName: string, val: any) => {
    if (!selectedPcr || !selectedPcr.id) {
      return;
    }

    if (val instanceof File) {
      addPcrFile(selectedPcr.id, propertyName, val);
    } else {
      propertyName === REMOVEFILE ? removeFile(selectedPcr.id, val) : updatePcrProperty(selectedPcr.id, propertyName, val);
    }
  };

  const removeFile = async (pcrId: string, val: string) => {
    try {
      //setUpdatingPcr(ProcessStatus.Fetching);
      await PcrService.removePcrFile(pcrId, val);
      loadPcr();
      //setUpdatingPcr(ProcessStatus.Success);
    } catch {
      //setUpdatingPcr(ProcessStatus.Error);
    }
  };

  const updatePcrProperty = async (pcrId: string, propertyName: string, val: any) => {
    let newValue: any;
    if (Array.isArray(val)) {
      newValue = val.map((s: any) => {
        return s.value;
      });
    } else {
      newValue = allDateValues.includes(propertyName) ? new Date(val) : val;
    }
    try {
      //setUpdatingPcr(ProcessStatus.Fetching);
      await PcrService.updateProperty(pcrId, propertyName, val === undefined ? null : newValue);
      //setUpdatingPcr(ProcessStatus.Success);
    } catch {
      //setUpdatingPcr(ProcessStatus.Error);
    }
  };

  return (
    <Container>
      <EditFull>
        <H2>PCR details</H2>
        <AdminBox style={{ backgroundColor: '#FDFDFD' }}>
          <CustomSearch>
            <LabelBox>
              <FixedLabel>Name</FixedLabel>
            </LabelBox>
            <ValueBox>
              <SmallRadiusInput
                style={{ boxSizing: 'border-box', width: '100%' }}
                defaultValue={selectedPcr?.name}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  e.currentTarget.value !== selectedPcr.name && onChangePcr('name', e.currentTarget.value)
                }
              />
            </ValueBox>
            <LabelBox>
              <FixedLabel>Registration number</FixedLabel>
            </LabelBox>
            <FirstDateValue>
              <SmallRadiusInput
                style={{ boxSizing: 'border-box', width: '100%' }}
                defaultValue={selectedPcr?.registrationNumber}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  e.currentTarget.value !== selectedPcr.registrationNumber &&
                  onChangePcr('registrationNumber', e.currentTarget.value)
                }
              />
            </FirstDateValue>
            <SecondDateLabel style={{ justifySelf: 'end', alignSelf: 'center' }}>
              <FixedLabel>Status</FixedLabel>
            </SecondDateLabel>
            <SecondDateValue style={{ width: '100%' }}>
              <Select
                // style={{
                //   borderColor: 'gray',
                //   fontSize: '14px',
                //   lineHeight: '17px',
                //   borderRadius: '3px',
                //   boxShadow: 'none !important',
                //   color: '#222',
                //   margin: 0,
                // }}
                options={statusOptions}
                placeholder={selectedPcr?.status}
                isLoading={!status || status.length === 0}
                isClearable={false}
                onChange={(e: any) => onChangePcr('status', e?.value)}
              />
            </SecondDateValue>
            <LabelBox>
              <FixedLabel>Version date</FixedLabel>
            </LabelBox>
            <FirstDateValue>
              <StyledDateInput
                type="date"
                defaultValue={formatDate(selectedPcr?.versionDate)}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('versionDate', e.currentTarget.value)}
              />
            </FirstDateValue>
            <SecondDateLabel style={{ justifySelf: 'end', alignSelf: 'center' }}>
              <FixedLabel>Version</FixedLabel>
            </SecondDateLabel>
            <SecondDateValue style={{ width: 'calc(100% +32px)' }}>
              <SmallRadiusInput
                style={{ boxSizing: 'border-box', width: '100%' }}
                defaultValue={selectedPcr?.version}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  e.currentTarget.value !== selectedPcr.version && onChangePcr('version', e.currentTarget.value)
                }
              />
            </SecondDateValue>
            <LabelBox>
              <FixedLabel>Short name</FixedLabel>
            </LabelBox>
            <ValueBox>
              <LargeRadiusInput
                style={{ boxSizing: 'border-box', width: '100%' }}
                defaultValue={selectedPcr?.shortName}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  e.currentTarget.value !== selectedPcr.shortName && onChangePcr('shortName', e.currentTarget.value)
                }
              />
            </ValueBox>
            <LabelBox>
              <FixedLabel>CPC name</FixedLabel>
            </LabelBox>
            <ValueBox>
              <LargeRadiusInput
                style={{ boxSizing: 'border-box', width: '100%' }}
                defaultValue={selectedPcr?.cpcName}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  e.currentTarget.value !== selectedPcr.cpcName && onChangePcr('cpcName', e.currentTarget.value)
                }
              />
            </ValueBox>
            <LabelBox>
              <FixedLabel>PCR review panel</FixedLabel>
            </LabelBox>
            <ValueBox>
              <LargeRadiusTextArea
                style={{ width: '100%', height: '17vh', resize: 'none' }}
                defaultValue={selectedPcr?.pcrReviewPanel}
                disabled
              />
            </ValueBox>
            <LabelBox>
              <FixedLabel>Chair of the PCR review</FixedLabel>
            </LabelBox>
            <ValueBox>
              <LargeRadiusInput
                style={{ boxSizing: 'border-box', width: '100%' }}
                defaultValue={selectedPcr?.chairOfThePCRReview}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  e.currentTarget.value !== selectedPcr.chairOfThePCRReview &&
                  onChangePcr('chairOfThePCRReview', e.currentTarget.value)
                }
              />
            </ValueBox>
            <LabelBox>
              <FixedLabel>Initiated date</FixedLabel>
            </LabelBox>
            <FirstDateValue>
              <StyledDateInput
                type="date"
                defaultValue={formatDate(selectedPcr?.initiatedDate)}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('initiatedDate', e.currentTarget.value)}
              />
            </FirstDateValue>
            <SecondDateLabel>
              <FixedLabel>Start open consultation date</FixedLabel>
            </SecondDateLabel>
            <SecondDateValue>
              <StyledDateInput
                type="date"
                defaultValue={formatDate(selectedPcr?.startOpenConsultationDate)}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangePcr('startOpenConsultationDate', e.currentTarget.value)
                }
              />
            </SecondDateValue>
            <LabelBox>
              <FixedLabel>Deadline open consultation date</FixedLabel>
            </LabelBox>
            <FirstDateValue>
              <StyledDateInput
                type="date"
                defaultValue={formatDate(selectedPcr?.deadlineOpenConsultationDate)}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangePcr('deadlineOpenConsultationDate', e.currentTarget.value)
                }
              />
            </FirstDateValue>
            <SecondDateLabel>
              <FixedLabel>Review and approval date</FixedLabel>
            </SecondDateLabel>
            <SecondDateValue>
              <StyledDateInput
                type="date"
                defaultValue={formatDate(selectedPcr?.reviewAndApprovalDate)}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangePcr('reviewAndApprovalDate', e.currentTarget.value)
                }
              />
            </SecondDateValue>
            <LabelBox>
              <FixedLabel>Publication date</FixedLabel>
            </LabelBox>
            <FirstDateValue>
              <StyledDateInput
                type="date"
                defaultValue={formatDate(selectedPcr?.publicationDate)}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('publicationDate', e.currentTarget.value)}
              />
            </FirstDateValue>
            <SecondDateLabel>
              <FixedLabel>Show update info until</FixedLabel>
            </SecondDateLabel>
            <SecondDateValue>
              <StyledDateInput
                type="date"
                defaultValue={formatDate(selectedPcr?.showUpdateInfoUntil)}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangePcr('showUpdateInfoUntil', e.currentTarget.value)
                }
              />
            </SecondDateValue>
            <LabelBox>
              <FixedLabel>Approval date</FixedLabel>
            </LabelBox>
            <FirstDateValue>
              <StyledDateInput
                type="date"
                defaultValue={formatDate(selectedPcr?.approvalDate)}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('approvalDate', e.currentTarget.value)}
              />
            </FirstDateValue>
            <SecondDateLabel>
              <FixedLabel>Deregistration date</FixedLabel>
            </SecondDateLabel>
            <SecondDateValue>
              <StyledDateInput
                type="date"
                defaultValue={formatDate(selectedPcr?.deregistrationDate)}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('deregistrationDate', e.currentTarget.value)}
              />
            </SecondDateValue>
            <LabelBox>
              <FixedLabel>Friendly URL</FixedLabel>
            </LabelBox>
            <FirstDateValue>
              <SmallRadiusInput
                style={{ boxSizing: 'border-box', width: '100%' }}
                defaultValue={selectedPcr?.friendlyUrl}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  e.currentTarget.value !== selectedPcr.friendlyUrl && onChangePcr('version', e.currentTarget.value)
                }
              />
            </FirstDateValue>
            <SecondDateLabel>
              <FixedLabel>Valid until date</FixedLabel>
            </SecondDateLabel>
            <SecondDateValue>
              <StyledDateInput
                type="date"
                defaultValue={formatDate(selectedPcr?.validUntil)}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('validUntil', e.currentTarget.value)}
              />
            </SecondDateValue>
            <LabelBox style={{ justifySelf: 'start', alignSelf: 'start', paddingTop: '1rem' }}>
              <FixedLabel>Text</FixedLabel>
            </LabelBox>
            <ValueBox>
              <HTMLEditor
                editorStyle={pcrEditorStyle}
                value={selectedPcr?.text}
                toolbarHidden={false}
                toolbar={customToolbarPcr}
                onEdit={(newValue: string) => onChangePcr('text', newValue)}
              ></HTMLEditor>
            </ValueBox>
            <LabelBox style={{ justifySelf: 'start', alignSelf: 'start', paddingTop: '1rem' }}>
              <FixedLabel>Status Text</FixedLabel>
            </LabelBox>
            <ValueBox>
              <HTMLEditor
                editorStyle={pcrEditorStyle}
                value={selectedPcr?.statusText}
                toolbarHidden={false}
                toolbar={customToolbarPcr}
                onEdit={(newValue: string) => onChangePcr('statusText', newValue)}
              ></HTMLEditor>
            </ValueBox>
            <LabelBox style={{ justifySelf: 'start', alignSelf: 'start', paddingTop: '1rem' }}>
              <FixedLabel>Description</FixedLabel>
            </LabelBox>
            <ValueBox>
              <HTMLEditor
                editorStyle={pcrEditorStyle}
                value={selectedPcr?.description}
                toolbarHidden={false}
                toolbar={customToolbarPcr}
                onEdit={(newValue: string) => onChangePcr('description', newValue)}
              ></HTMLEditor>
            </ValueBox>
            <LabelBox style={{ justifySelf: 'start', alignSelf: 'start', paddingTop: '1rem' }}>
              <FixedLabel>Legal Note</FixedLabel>
            </LabelBox>
            <ValueBox>
              <HTMLEditor
                editorStyle={pcrEditorStyle}
                value={selectedPcr?.legalNote}
                toolbarHidden={false}
                toolbar={customToolbarPcr}
                onEdit={(newValue: string) => onChangePcr('legalNote', newValue)}
              ></HTMLEditor>
            </ValueBox>
            <LabelBox>
              <FixedLabel>Prepared by</FixedLabel>
            </LabelBox>
            <ValueBox>
              <SmallRadiusInput
                style={{ boxSizing: 'border-box', width: '100%' }}
                type="textarea"
                defaultValue={selectedPcr?.preparedBy}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  e.currentTarget.value !== selectedPcr.preparedBy && onChangePcr('preparedBy', e.currentTarget.value)
                }
              />
            </ValueBox>
            <LabelBox>
              <FixedLabel>Contributors</FixedLabel>
            </LabelBox>
            <ValueBox>
              <SmallRadiusInput
                style={{ boxSizing: 'border-box', width: '100%' }}
                type="textarea"
                defaultValue={selectedPcr?.contributors}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  e.currentTarget.value !== selectedPcr.contributors && onChangePcr('contributors', e.currentTarget.value)
                }
              />
            </ValueBox>
            <LabelBox>
              <FixedLabel>Keywords</FixedLabel>
            </LabelBox>
            <ValueBox>
              <SmallRadiusInput
                style={{ boxSizing: 'border-box', width: '100%' }}
                type="textarea"
                defaultValue={selectedPcr?.keywords}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  e.currentTarget.value !== selectedPcr.keywords && onChangePcr('keywords', e.currentTarget.value)
                }
              />
            </ValueBox>
            <LabelBox>
              <FixedLabel>Pre-verified tools</FixedLabel>
            </LabelBox>
            <ValueBox>
              <Select
                // style={{
                //   borderColor: 'gray',
                //   fontSize: '14px',
                //   lineHeight: '17px',
                //   borderRadius: '3px',
                //   boxShadow: 'none !important',
                //   color: '#222',
                //   margin: 0,
                // }}
                options={preVerifiedToolsOptions}
                isMulti={true}
                defaultValue={selectedPcr?.preverifiedTools?.map((pre) => {
                  return { value: pre.id, label: pre.name } as Option;
                })}
                placeholder={''}
                isLoading={!preVerifiedToolsOptions || preVerifiedToolsOptions.length === 0}
                isClearable={false}
                onChange={(e) => onChangePcr('preverifiedTools', e)}
              />
            </ValueBox>
            <LabelBox>
              <FixedLabel>Category</FixedLabel>
            </LabelBox>
            <ValueBox>
              <Select
                // style={{
                //   borderColor: 'gray',
                //   fontSize: '14px',
                //   lineHeight: '17px',
                //   borderRadius: '3px',
                //   boxShadow: 'none !important',
                //   color: '#222',
                //   margin: 0,
                // }}
                options={productCategoriesOptions}
                isMulti={true}
                defaultValue={selectedPcr?.productCategories?.map((category) => {
                  return { value: category.id, label: category.title } as Option;
                })}
                placeholder={''}
                isLoading={!productCategoriesOptions || productCategoriesOptions.length === 0}
                isClearable={false}
                onChange={(e) => onChangePcr('productCategories', e)}
              />
            </ValueBox>
            <LabelBox>
              <FixedLabel>Files</FixedLabel>
            </LabelBox>
            <ValueBox>
              <UploadFile
                accept=".pdf"
                title="Please upload your PCR documents."
                uploadFile={uploadFile}
                disabled={false}
                documents={selectedPcr?.documents}
                onChangeFile={onChangePcr}
                field="PCR"
                maxSize={false}
              />
            </ValueBox>
            <LabelBox>
              <FixedLabel>EN15804 compliant</FixedLabel>
            </LabelBox>
            <ValueBox style={{ minHeight: 0 }}>
              <input
                type="checkbox"
                defaultChecked={selectedPcr?.is15804Compliant}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangePcr('is15804Compliant', e.currentTarget.checked)
                }
              />
            </ValueBox>
          </CustomSearch>
        </AdminBox>
      </EditFull>
      {/* {error && <div style={{ color: 'red', gridColumnStart: '2', gridColumnEnd: '3' }}>{error}</div>} */}
      {/* {isUpdated && (
        !error && <div style={{ color: 'green', gridColumnStart: '2', gridColumnEnd: '3' }}>
          PCR is updated Successfully.
        </div>
      )} */}
    </Container>
  );
};

const FixedLabel = styled(Label)`
  flex: 0 0 200px;
  align-self: start;
  margin: 0.5rem 1rem 0.5rem 0; /*risky change? */
  padding-top: 10%;

  > svg {
    margin: 0 0.5rem;
  }

  > div + svg {
    margin: 0;
  }
`;

const StyledDateInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: solid 1px gray;
  height: 38px;
  border-radius: 3px;
  padding: 0 1rem;
`;

const CustomSearch = styled.div`
  grid-template-columns: 30% 70%;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  display: grid;
  grid-template-rows: 3;
  justify-content: start;
  align-items: center;
  ${(props) => props.theme.media.tablet} {
    max-width: 80%;
  }
  ${(props) => props.theme.media.desktop} {
    grid-template-columns: 30% 20% 5% 30% 20%;
  }
`;

export const ValueBox = styled.div`
  min-height: 38px;
  grid-column-start: 2;
  grid-column-end: 3;
  align-self: center;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 2;
    grid-column-end: 6;
  }
`;
const FirstDateValue = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  align-self: center;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 2;
    grid-column-end: 3;
  }
`;

const SecondDateLabel = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  align-self: center;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 4;
    grid-column-end: 5;
  }
`;

const SecondDateValue = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  align-self: center;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 5;
    grid-column-end: 6;
  }
`;

export const FileRow: React.FunctionComponent<{ file?: FileModel2; onRemoveFile: any; disabled: boolean }> = ({
  file,
  onRemoveFile,
  disabled,
}) => {
  if (!file || !file.id) {
    return null;
  }

  const onRemove = async () => {
    if (!file || !file.id) {
      return null;
    }
    onRemoveFile && onRemoveFile(file?.id as string);
  };

  const fileUrl = FileService.getImageUrl(file?.id || '');

  return (
    <FileBoxRow>
      <em>{file?.name} </em>{' '}
      <span>
        {!disabled && (
          <img
            alt="Remove file"
            src={trashIcon}
            style={{ width: '20px', margin: '0 0.5rem', cursor: 'pointer' }}
            onClick={onRemove}
          />
        )}
        {file && (
          <a href={fileUrl} title="Download file" target="_blank" rel="noopener noreferrer">
            <img alt="Download file" src={downloadIcon} style={{ width: '20px', margin: '0 0.5rem', cursor: 'pointer' }} />
          </a>
        )}
      </span>
    </FileBoxRow>
  );
};

export const FileBox = styled.div`
  background-color: ${(props) => props.theme.colors.regionColorLight};
  padding: 2rem;
  flex: 1 0 auto;

  > h3 {
    margin-top: 0;
  }
`;

export const FileBoxRow = styled.div`
  display: flex;
  margin: 1rem 0;
  justify-content: space-between;

  > em {
    flex: 0 1 auto;
    max-width: fit-content;
  }
  > span {
    flex: 0 0 auto;
  }
`;

export const RegBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-left: solid 3px green;
  margin: 0.5rem 0;
  background-color: #f3f3f3;

  @media print {
    display: none;
  }
`;

export default EditPcr;
