import React from 'react';
import { EpdLinks } from 'routes/EpdRoutes';
import { EPDModel } from 'services/EpdClient';

import { Card, CardHeading, CardName, CardStatus } from './styles';

type TProps = {
  companyid: string;
  epd: EPDModel;
  background: any;
};

const EpdCard = ({ companyid, epd, background }: TProps) => {
  return (
    <Card to={EpdLinks.epd(epd.id)} background={background}>
      <CardHeading>{epd.fullIdentificationNumber}</CardHeading>
      <CardName>{epd.title}</CardName>
      <CardStatus type={epd.status}>
        <span>{epd.status}</span>
      </CardStatus>
    </Card>
  );
};

export default EpdCard;
