import UploadGeneralCertificateDialog from 'components/v2/copy/dialogs/UploadGeneralCertificateDialog';
import { StatusApprovedIcon, StatusAttentionIcon } from 'components/v2/icons';
import { ButtonUploadIcon } from 'components/v2/icons';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FileModel } from 'services/EpdClient';
import EpdStateService from 'services/EpdStateService';
import styled from 'styled-components';

const showStatusIcon = (processCertificatesFiles: FileModel[] | undefined) => {
  if (!processCertificatesFiles || processCertificatesFiles.length == 0) {
    return <StatusAttentionIcon />;
  }
  return <StatusApprovedIcon />;
};

const EpdPanelHeaderProcessCertificateTemplate = (
  options: any,
  processCertificatesFiles: FileModel[] | undefined,
  epdCopyGroupId: string,
  updateFilesArray: any,
  canUpload: boolean
) => {
  const { t } = useTranslation();
  const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
  const className = `${options.className} justify-content-start w-full`;
  const [isOpenedCertificationReportDialog, setIsOpenedCertificationReportDialog] = useState<boolean>(false);
  const [fileLoadingCount, setFileLoadingCount] = useState<number>(0);

  const onUpload = async (name: string, value: any) => {
    if (value instanceof File) {
      const fileName = value.name;
      const fileType = value.type;
      const reader = new FileReader();
      reader.onload = async () => {
        const fileBlob = reader.result as ArrayBuffer;
        if (fileBlob) {
          const blob = new Blob([new Uint8Array(fileBlob)], { type: fileType });
          if (blob.size > 10000000) {
            toast.error(t('epdWizard.messages.errorTooLargeFile') as string, {
              position: 'top-center',
            });
            return;
          }
          try {
            setFileLoadingCount((prev) => prev + 1);
            const result = await EpdStateService.addCopyGroupFile(epdCopyGroupId, name, fileName, blob);
            if (result && result.processCertificatesFiles) {
              updateFilesArray(result.processCertificatesFiles);
            }
          } catch {
          } finally {
            setFileLoadingCount((prev) => prev - 1);
          }
        }
      };
      reader.readAsArrayBuffer(value);
    }
  };
  const onDelete = async (name: string, value: any) => {
    var result = await EpdStateService.removeCopyGroupFile(epdCopyGroupId, value);
    if (result && result.processCertificatesFiles) {
      updateFilesArray(result.processCertificatesFiles);
    }
  };
  return (
    <div className={className} style={{ backgroundColor: '#FFFFFF', marginTop: '1rem', minHeight: '70px' }}>
      {showStatusIcon(processCertificatesFiles)}
      <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ margin: '0 0.5rem' }}>
        <span className={toggleIcon}></span>
      </button>
      <Container>
        <div className="flex w-full">
          <EpdTitle>Process certificate</EpdTitle>
        </div>
        {canUpload && (
          <div className="flex">
            <Button
              className="white-space-nowrap"
              label="Upload documents"
              icon={ButtonUploadIcon}
              text
              onClick={() => setIsOpenedCertificationReportDialog(true)}
            />
            <UploadGeneralCertificateDialog
              header="Upload documents"
              isOpened={isOpenedCertificationReportDialog}
              onHide={() => setIsOpenedCertificationReportDialog(false)}
              files={processCertificatesFiles}
              onUpload={onUpload}
              onDelete={onDelete}
              fileLoading={fileLoadingCount > 0}
            />
          </div>
        )}
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 38px;
`;

const EpdTitle = styled.div`
  ${(props) => props.theme.typography.bodyBody14Semibold}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.75rem 0rem;
  margin: 0;
`;

export default EpdPanelHeaderProcessCertificateTemplate;
