import { ReactComponent as AddImageIcon } from 'assets/images/icons/plus.svg';
import { DeleteImageIcon } from 'components/v2/icons';
import { REMOVEFILE } from 'constants/constants';
import { UploadButton } from 'pages/wizard/WizardStep2';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileModelPlus } from 'services/EpdClient';
import FileService from 'services/FileService';
import styled, { css } from 'styled-components';

import { confirmDelete } from '../confirm-dialog/ConfirmDelete';
import { NoImages } from './NoImages';

interface ImageUploadProps {
  name: string;
  images?: FileModelPlus[];
  onUpload: (e: any) => any;
  onReorder: (imgIds: string[]) => any;
  onChangeEpd: any;
  disabled?: boolean;
}

export const ImagesUpload: React.FunctionComponent<ImageUploadProps> = ({
  name,
  images,
  onUpload,
  onReorder,
  onChangeEpd,
  disabled,
}) => {
  const { t } = useTranslation();
  const [dragId, setDragId] = useState<any>(null);
  const [previewImages, setPreviewImages] = useState<PImg[] | undefined>(undefined);

  interface PImg {
    id: number;
    imgId?: string;
    imgName?: string | undefined;
    epdFileId?: string;
  }

  useEffect(() => {
    setPreviewImages(
      images?.map((img, index) => {
        return { id: index + 1, imgId: img.id, imgName: img.name, epdFileId: img.epdFileId } as PImg;
      })
    );
  }, [images]);

  const deleteImage = (img: any) => {
    confirmDelete({
      caption: t('confirmModal.deleteImage'),
      onConfirm: () => onChangeEpd(REMOVEFILE, img?.imgId),
    });
  };

  const handleOver = (ev: any) => {
    ev.preventDefault();
  };

  const handleDrag = (ev: any) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev: any) => {
    ev.preventDefault();
    const dragImage = previewImages?.find((image) => image.id.toString() === dragId);
    const dropImage = previewImages?.find((image) => image.id.toString() === ev.currentTarget.id);
    if (dragImage && dropImage) {
      const arr = moveItem(dragImage?.id - 1, dropImage?.id - 1);
      if (arr && arr.length > 1) {
        onReorder(arr.map((i) => i.epdFileId || ''));
      }
      setPreviewImages(arr);
    }
  };

  const moveItem = (from: number, to: number) => {
    const f = previewImages?.splice(from, 1)[0];
    if (f) {
      previewImages?.splice(to, 0, f);
    }

    return previewImages?.map((img, index) => {
      return { id: index + 1, imgId: img.imgId, imgName: img.imgName, epdFileId: img.epdFileId } as PImg;
    });
  };

  const getImageBox = (img: any) => {
    return (
      <ImageBox
        key={img.id}
        id={img.id}
        draggable={!disabled}
        onDragOver={(e) => handleOver(e)}
        onDragStart={(e) => handleDrag(e)}
        onDrop={(e) => handleDrop(e)}
      >
        <ImageBoxImage>{img.imgId && <img alt={`Custom ${name}`} src={FileService.getImageUrl(img.imgId)} />}</ImageBoxImage>
        {img.id == 1 && <EpdCover>{'EPD cover'}</EpdCover>}
        {!disabled && (
          <ImageRemove onClick={() => deleteImage(img)}>
            <DeleteImageIcon />
          </ImageRemove>
        )}
      </ImageBox>
    );
  };

  const anyImages = previewImages && previewImages.length > 0;
  const [dragActive, setDragActive] = React.useState(false);
  const handleNewImageDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped
      onUpload(e.dataTransfer.files[0]);
    }
  };

  const handleNewImageDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      window.addEventListener(
        'dragover',
        function (e) {
          /* eslint-disable */
          e = e || event;
          e.preventDefault();
        },
        false
      );
      window.addEventListener(
        'drop',
        function (e) {
          /* eslint-disable */
          e = e || event;
          e.preventDefault();
        },
        false
      );
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  return (
    <ImagesPanel
      disabled={disabled}
      anyImages={anyImages}
      dragActive={dragActive}
      draggable={!disabled}
      onDragEnter={handleNewImageDrag}
      onDragLeave={handleNewImageDrag}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleNewImageDrop}
    >
      {anyImages ? previewImages.map(getImageBox) : <NoImages onUpload={onUpload} disabled={disabled} />}
      {anyImages && !disabled && (
        <UploadButton>
          <AddImageBox>
            <AddImageIcon />
          </AddImageBox>
          <input type="file" accept=".bmp,.jpeg,.jpg,.png" name={name} onChange={onUpload} disabled={disabled} />
        </UploadButton>
      )}
    </ImagesPanel>
  );
};

const InnerBox = styled.div`
  width: auto;
  min-width: 80px;
  max-width: 300px;
  height: 80px;
  border-radius: 4px;
  border: 0.5px solid ${(props) => props.theme.colors.elementsInputBorder};
  position: relative;
  display: flex;
  margin: 7px;
`;

const ImagesPanel = styled.div<{ disabled?: boolean; anyImages?: boolean; dragActive?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 7px;
  min-height: 106px;
  border-width: 1px;
  border-style: ${(props) => (!props.disabled && !props.anyImages ? 'dashed' : 'solid')};
  border-color: ${(props) =>
    props.disabled
      ? props.theme.colors.elementsFilledInput
      : props.anyImages
      ? props.theme.colors.elementsInputBorder
      : props.theme.colors.secondaryLightGreen};

  background-color: ${(props) =>
    props.disabled ? props.theme.colors.elementsFilledInput : props.theme.colors.primaryWhite};
  color: ${(props) => (props.disabled ? props.theme.colors.primaryBlack50 : props.theme.colors.primaryGreen)};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  border-radius: 4px;
  transform-style: preserve-3d;

  ${(props) =>
    props.dragActive &&
    css`
      && > ${InnerBox}, ${UploadButton} {
        transform: translateZ(-10px);
        pointer-events: none;
      }
      opacity: 0.5;
      animation: pulsate 0.1s infinite alternate;
      border-color: black;
      @keyframes pulsate {
        0% {
          border-style: dashed;
        }
        100% {
          border-style: dotted;
        }
      }
    `}
`;

const ImageBox = styled(InnerBox)`
  flex-wrap: wrap;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.primaryWhite};
`;

const AddImageBox = styled(InnerBox)`
  background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const ImageBoxImage = styled.div`
  flex: 0 0 auto;
  align-self: center;
  width: auto;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 90%;
    max-height: 90%;
  }
`;

const ImageRemove = styled.div`
  top: 1px;
  position: absolute;
  right: 5px;
  cursor: pointer;
`;

const EpdCover = styled.div`
  position: absolute;
  left: 0;
  display: inline-flex;
  padding: 0px 3px;
  align-items: flex-start;
  border-radius: 4px 0px;
  background-color: ${(props) => props.theme.colors.primaryOrange};
  color: ${(props) => props.theme.colors.primaryWhite};
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 150% */
`;
