import 'react-toastify/dist/ReactToastify.css';

import PrivateRoute from 'components/PrivateRoute';
import CompanyCollections from 'pages/CompanyCollections';
import CompanySelector from 'pages/CompanySelector';
import DetailsPcr from 'pages/DetailsPcr';
import ManageCompany from 'pages/ManageCompany';
import PcrDashboard from 'pages/PcrDashboard';
import RegisterCompany from 'pages/account/RegisterCompany';
import AdminCompanies from 'pages/admin/companies/Companies';
import EditCompany from 'pages/admin/companies/EditCompany';
import AdminDataSources from 'pages/admin/dataSources/DataSources';
import EditDataSource from 'pages/admin/dataSources/EditDataSource';
import EditEpd from 'pages/admin/epds/EditEpd';
import AdminEpds from 'pages/admin/epds/Epds';
import IlcdEpd from 'pages/admin/integrations/ilcdEpd';
import IlcdEpdMapping from 'pages/admin/integrations/ilcdEpd/ilcdEpdMapping';
import IlcdEpdMappingList from 'pages/admin/integrations/ilcdEpd/IlcdEpdMappingList';
import FortnoxIntegrationPage from 'pages/admin/fortnox/FortnoxIntegrationPage';
import FortnoxInvoicesPage from 'pages/admin/fortnox/FortnoxInvoicesPage';
import EditInvoice from 'pages/admin/invoices/EditInvoice';
import AdminInvoices from 'pages/admin/invoices/Invoices';
import EditPcr from 'pages/admin/pcrs/EditPcr';
import AdminPcrs from 'pages/admin/pcrs/Pcrs';
import AdminKpis from 'pages/admin/reports/Kpis';
import AdminVerifiers from 'pages/admin/reports/Verifiers';
import AdminEPDsReport from 'pages/admin/reports/EPDs';
import EditTool from 'pages/admin/tools/EditTool';
import AdminTools from 'pages/admin/tools/Tools';
import EditUser from 'pages/admin/users/EditUser';
import AdminUsers from 'pages/admin/users/Users';
import Dashboard from 'pages/dashboard/Dashboard';
import WelcomeToPortal from 'pages/dashboard/WelcomeToPortal';
import Invoice from 'pages/invoicing/Invoice';
import Invoices from 'pages/invoicing/Invoices';
import ManageCollection from 'pages/manage-collection/ManageCollection';
import PcrWizard from 'pages/pcrWizard/PcrWizard';
import AdminLicensees from 'pages/v2/admin/licensees/Licensees';
import Collections from 'pages/v2/collections/Collections';
import EditCollection from 'pages/v2/collections/EditCollection';
import CopyEpdWizard from 'pages/v2/copy/CopyEpdWizard';
import ConfirmEmail from 'pages/v2/login/ConfirmEmail';
import ForgotPassword from 'pages/v2/login/ForgotPassword';
import Login from 'pages/v2/login/Login';
import RegisterUser from 'pages/v2/login/RegisterUser';
import ResetPassword from 'pages/v2/login/ResetPassword';
import Welcome from 'pages/v2/login/Welcome';
import EpdWizard from 'pages/wizard/EpdWizard';
import { Route, Switch } from 'react-router-dom';
import EpdRoutes from 'routes/EpdRoutes';
import LcaValidation from 'pages/wizard/LcaValidation';

//v2

type TProps = {
  hasLoggedInBefore: boolean;
};

const NewUIRoutes = ({ hasLoggedInBefore }: TProps) => {
  return (
    <Switch>
      <Switch>
        <Route exact path={EpdRoutes.welcome}>
          {hasLoggedInBefore ? <Login /> : <Welcome />}
        </Route>
        <Route exact path={EpdRoutes.login}>
          <Login />
        </Route>
        <Route exact path={EpdRoutes.account.register.user}>
          <RegisterUser />
        </Route>
        <Route exact path={EpdRoutes.account.forgotPassword}>
          <ForgotPassword />
        </Route>
        <Route exact path={EpdRoutes.account.confirmEmail}>
          <ConfirmEmail />
        </Route>
        <Route exact path={EpdRoutes.account.resetPassword}>
          <ResetPassword />
        </Route>
        <PrivateRoute exact path={EpdRoutes.dashboard} component={Dashboard} />
        <PrivateRoute exact path={EpdRoutes.epd} component={EpdWizard} />
        <PrivateRoute exact path={EpdRoutes.manageCompany} component={ManageCompany} />
        <PrivateRoute exact path={EpdRoutes.invoice} component={Invoice} />
        <PrivateRoute exact path={EpdRoutes.invoices} component={Invoices} />
        <PrivateRoute exact path={EpdRoutes.account.register.company} component={RegisterCompany} />
        <PrivateRoute exact path={EpdRoutes.admin.companies.companies} component={AdminCompanies} />
        <PrivateRoute exact path={EpdRoutes.admin.companies.editCompany} component={EditCompany} />
        <PrivateRoute exact path={EpdRoutes.admin.invoices.invoices} component={AdminInvoices} />
        <PrivateRoute exact path={EpdRoutes.admin.invoices.editInvoice} component={EditInvoice} />
        <PrivateRoute exact path={EpdRoutes.admin.users.users} component={AdminUsers} />
        <PrivateRoute exact path={EpdRoutes.admin.users.editUser} component={EditUser} />
        <PrivateRoute exact path={EpdRoutes.admin.epds.epds} component={AdminEpds} />
        <PrivateRoute exact path={EpdRoutes.admin.epds.editEpd} component={EditEpd} />
        <PrivateRoute exact path={EpdRoutes.admin.pcrs.pcrs} component={AdminPcrs} />
        <PrivateRoute exact path={EpdRoutes.admin.pcrs.editPcr} component={EditPcr} />
        <PrivateRoute exact path={EpdRoutes.admin.tools.tools} component={AdminTools} />
        <PrivateRoute exact path={EpdRoutes.admin.tools.editTool} component={EditTool} />
        <PrivateRoute exact path={EpdRoutes.admin.dataSources.dataSources} component={AdminDataSources} />
        <PrivateRoute exact path={EpdRoutes.admin.dataSources.editDataSource} component={EditDataSource} />
        <PrivateRoute exact path={EpdRoutes.admin.licensees.licensees} component={AdminLicensees} />
        <PrivateRoute exact path={EpdRoutes.admin.kpis.summary} component={AdminKpis} />
        <PrivateRoute exact path={EpdRoutes.admin.verifiers.summary} component={AdminVerifiers} />
        <PrivateRoute exact path={EpdRoutes.admin.epdsreport.summary} component={AdminEPDsReport} />
        <PrivateRoute exact path={EpdRoutes.pcr.dashboard} component={PcrDashboard} />
        <PrivateRoute exact path={EpdRoutes.pcr.create} component={PcrWizard} />
        <PrivateRoute exact path={EpdRoutes.pcr.details} component={DetailsPcr} />
        <PrivateRoute exact path={EpdRoutes.manageCollection} component={ManageCollection} />
        <PrivateRoute exact path={EpdRoutes.manageCollections} component={CompanyCollections} />
        <PrivateRoute exact path={EpdRoutes.manageCollectionsEmpty} component={WelcomeToPortal} />
        <PrivateRoute exact path={EpdRoutes.selectCompany} component={CompanySelector} />
        <PrivateRoute exact path={EpdRoutes.selectCompanyEmpty} component={WelcomeToPortal} />
        <PrivateRoute exact path={EpdRoutes.admin.integration.ilcdEpd} component={IlcdEpd} />
        <PrivateRoute exact path={EpdRoutes.admin.integration.ilcdEpdMapping} component={IlcdEpdMapping} />
        <PrivateRoute exact path={EpdRoutes.admin.integration.ilcdEpdMappingList} component={IlcdEpdMappingList} />
        <PrivateRoute exact path={EpdRoutes.admin.integration.fortnoxIntegrationPage} component={FortnoxIntegrationPage} />
        <PrivateRoute exact path={EpdRoutes.admin.integration.FortnoxInvoicesPage} component={FortnoxInvoicesPage} />
        <PrivateRoute exact path={EpdRoutes.collections} component={Collections} />
        <PrivateRoute exact path={EpdRoutes.editCollection} component={EditCollection} />
        <PrivateRoute exact path={EpdRoutes.copyEpd} component={CopyEpdWizard} />
        <PrivateRoute exact path={EpdRoutes.editCopyEpd} component={CopyEpdWizard} />
        <PrivateRoute exact path={EpdRoutes.lcaValidation} component={LcaValidation} />
      </Switch>
    </Switch>
  );
};

export default NewUIRoutes;
