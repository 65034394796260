import logo from 'assets/images/portal-logo.svg';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Dropdown } from 'primereact/dropdown';
import { Menu } from 'primereact/menu';
import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';
import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EpdRoutes, { EpdLinks } from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import styled from 'styled-components';

import { ButtonTopHeader } from './buttons';
import { toaster } from './toast';

const Header: React.FunctionComponent<{ onChangeUI: any }> = ({ onChangeUI }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);
  const { companyId, company, companyAccountId, changeCompanyAccount } = useContext(CompanyContext);
  const menuProfile = useRef<Menu>(null);
  const menuNotifications = useRef<Menu>(null);

  const user = AuthService.getMenuUserInfo();
  const [selectedLanguage, setSelectedLanguage] = useState({ name: 'English', code: 'EN' });
  const languages = [
    { name: 'English', code: 'EN' },
    { name: 'Svenska', code: 'SV' },
  ];

  React.useEffect(() => {
    setIsLoggedIn(AuthService.isAuthenticated());
    history.listen(() => setIsLoggedIn(AuthService.isAuthenticated()));
  }, [history]);

  const selectedCountryTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.code}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const itemsMainMenu: MenuItem[] = [
    {
      label: 'Dashboard',
      command: () => {
        history.push(EpdLinks.dashboard());
      },
    },
    {
      label: 'Organizations',
      command: () => {
        history.push(EpdLinks.selectCompany(companyId || ''));
      },
    },
    {
      label: 'Collections',
      command: () => {
        history.push(EpdLinks.collections());
      },
    },

    {
      label: 'External library',
      items: [
        {
          label: 'EPD library',
          icon: 'pi pi-fw pi-external-link',
          url: 'https://environdec.com/library',
          target: '_blank',
        },
        {
          label: 'PCR library',
          icon: 'pi pi-fw pi-external-link',
          url: t('header.pcrLibraryLink'),
          target: '_blank',
        },
        {
          label: 'Find verifier',
          icon: 'pi pi-fw pi-external-link',
          url: 'https://www.environdec.com/resources/verifiers',
          target: '_blank',
        },
      ],
    },
    {
      label: 'Registers',
      items: [
        {
          label: 'Organizations',
          command: () => {
            history.push(EpdRoutes.admin.companies.companies);
          },
        },
        {
          label: 'Invoices',
          command: () => {
            history.push(EpdRoutes.admin.invoices.invoices);
          },
        },
        {
          label: 'Users',
          command: () => {
            history.push(EpdRoutes.admin.users.users);
          },
        },
        {
          label: 'PCRs',
          command: () => {
            history.push(EpdRoutes.admin.pcrs.pcrs);
          },
        },
        {
          label: 'EPDs',
          command: () => {
            history.push(EpdRoutes.admin.epds.epds);
          },
        },
        {
          label: 'Licensees',
          command: () => {
            history.push(EpdRoutes.admin.licensees.licensees);
          },
        },
        {
          label: 'ILCD',
          command: () => {
            history.push(EpdRoutes.admin.integration.ilcdEpd);
          },
        },
        {
          label: 'Tools',
          command: () => {
            history.push(EpdRoutes.admin.tools.tools);
          },
        },
        {
          label: 'Data sources',
          command: () => {
            history.push(EpdRoutes.admin.dataSources.dataSources);
          },
        },
        {
          label: 'Fortnox',
          command: () => {
            history.push(EpdRoutes.admin.integration.fortnoxIntegrationPage);
          },
        },
        {
          label: 'Fortnox Invoice',
          command: () => {
            history.push(EpdRoutes.admin.integration.FortnoxInvoicesPage);
          },
        },
      ],
    },
  ];

  const itemsProfile: MenuItem[] = [
    { label: `${user?.firstName} ${user?.lastName}`, items: [] },
    { label: user?.email, disabled: true },
    { label: company?.name, items: [] },
    { separator: true },
    { label: 'Account settings' },
    { label: 'Organization settings' },
    { label: 'Switch organization', icon: 'pi pi-sync' },
    { label: 'Switch theme', icon: 'pi pi-palette' },
    { label: 'Switch to old UI', icon: 'pi pi-arrow-right-arrow-left', command: () => onChangeUI() },
    { separator: true },
    { label: t('logout.title'), icon: 'pi pi-sign-out', command: () => AuthService.logout(history), visible: isLoggedIn },
  ];

  const start = <HeaderLogo src={logo} alt="EPD International" />;
  const end = (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <LanguageSelector
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.value)}
          options={languages}
          optionLabel="name"
          valueTemplate={selectedCountryTemplate}
        />

        <StyledMenu model={itemsProfile} popup ref={menuProfile} id="popup_menu_profile" />
        <ButtonTopHeader icon="pi pi-user" onClick={(event) => menuProfile!.current!.toggle(event)} />

        <ButtonTopHeader
          icon="pi pi-bell"
          onClick={() => {
            toaster({ severity: 'info', summary: 'Under construction', details: "Here you'll see notifications" });
          }}
        >
          {true && <NotificationMark />}
        </ButtonTopHeader>
      </div>
    </>
  );

  return <>{AuthService.isAuthenticated() && <MainHeader model={itemsMainMenu} start={start} end={end} />}</>;
};

const MainHeader = styled(Menubar)`
  border-radius: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 10;

  @media print {
    display: none;
  }
`;

const HeaderLogo = styled.img`
  align-self: center;
  margin: 0 40px;
  height: 30px;
`;

const NotificationMark = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.statusLabelRedText};
  right: 8px;
  top: 6px;
`;

const StyledMenu = styled(Menu)`
  &.p-menu {
    width: auto;
    white-space: nowrap;
    display: flex;
  }
`;

const LanguageSelector = styled(Dropdown)`
  max-width: 5rem;
  border: 0;
  padding: 0;
  box-shadow: none !important;
  & span.p-inputtext {
    padding-left: 0;
    padding-right: 0;
  }
`;

export default Header;
