import { PrimaryButton, PrimarySmallButton, SecondaryButton } from 'components/v2/buttons';
import { confirmDelete } from 'components/v2/confirm-dialog/ConfirmDelete';
import { HelpBox } from 'components/v2/help-boxes';
import SmartLabel from 'components/v2/labels/SmartLabel';
import { toaster } from 'components/v2/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { RadioButton } from 'primereact/radiobutton';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import CopyEpdService from 'services/CopyEpdService';
import { EPDCopyGroupStatus, EPDModel, VersionStateType } from 'services/EpdClient';
import styled from 'styled-components';
import { CheckBoxPanel, EPDStatusBadge } from 'styles/v2/Styles.styled';

type TProps = {
  epd: EPDModel;
  refetchGroupData: any;
  onConfirmUpdate: any;
  isReadOnly: boolean;
  isMenuEnabled: boolean;
  groupStatus: EPDCopyGroupStatus;
};

const HeaderActionPanel = ({ epd, refetchGroupData, onConfirmUpdate, isReadOnly, isMenuEnabled, groupStatus }: TProps) => {
  const { epdGroupId } = useParams<{ epdGroupId: string }>();
  const menu = useRef<Menu>(null);
  const [declinedPublicationEditChoice, setDeclinedPublicationEditChoice] = useState<string>();
  const [isEditConfirmOpened, setIsEditConfirmOpened] = useState<boolean>(false);

  const onAcceptUnlinkEpd = async () => {
    try {
      await CopyEpdService.unlinkEpdFromCopyGroup(epdGroupId, epd?.versionId || '');
      toaster({ severity: 'success', summary: 'Success', details: 'The EPD has been unlinked' });
      refetchGroupData();
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: 'EPD has not been unlinked due to unexpected error' });
      console.error(error);
    }
  };

  const onAcceptUpdateWithVerification = async () => {
    try {
      await CopyEpdService.editApprovedWithVerification(epd?.id || '');
      toaster({ severity: 'success', summary: 'Success', details: 'The EPD has been set to edit mode' });
      refetchGroupData();
    } catch (error) {
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD has not been set to update mode due to unexpected error',
      });
      console.error(error);
    }
  };

  const onAcceptEditorialUpdate = async () => {
    try {
      await CopyEpdService.editApprovedEditorial(epd?.id || '');
      toaster({ severity: 'success', summary: 'Success', details: 'The EPD has been set to editorial update mode' });
      refetchGroupData();
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: 'EPD has not been set to editorial update' });
      console.error(error);
    }
  };

  const onAcceptDeleteEpd = async () => {
    try {
      await CopyEpdService.deleteEpdFromCopyGroup(epdGroupId, epd?.versionId || '');
      toaster({ severity: 'success', summary: 'Success', details: 'The EPD has been deleted' });
      refetchGroupData();
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: 'EPD has not been deleted due to unexpected error' });
      console.error(error);
    }
  };

  const items: MenuItem[] = [
    {
      label: 'Unlink EPD from group',
      command: () => {
        confirmDelete({
          caption: `Are you sure you want to unlink EPD "${epd?.name}" from this group?`,
          description: (
            <div>
              This EPD will be unlinked from the copy group.
              <br /> You will be able to access the unlinked EPD from your dashboard.
            </div>
          ),
          acceptLabel: 'Unlink',
          rejectLabel: 'Cancel',
          onConfirm: onAcceptUnlinkEpd,
        });
      },
    },
    {
      label: 'Delete EPD',
      command: () => {
        confirmDelete({
          caption: `Are you sure you want to delete the EPD "${epd?.name}"?`,
          description: <div>The EPD data will be lost.</div>,
          acceptLabel: 'Delete',
          rejectLabel: 'Cancel',
          onConfirm: onAcceptDeleteEpd,
        });
      },
      visible: true,
    },
    {
      label: 'Edit EPD',
      command: () => setIsEditConfirmOpened(true),
      visible:
        (groupStatus === EPDCopyGroupStatus.DeclinedPublication ||
          groupStatus === EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin) &&
        (epd.versionState === VersionStateType.Approved || epd.versionState === VersionStateType.LicenseeAdminVerification),
    },
  ];

  return (
    <>
      <div className="flex align-items-center">
        {epd.versionState === VersionStateType.DeclinedVerification && !isReadOnly && (
          <>
            <PrimarySmallButton className="white-space-nowrap" label="Confirm update" onClick={onConfirmUpdate} />
            <Divider layout="vertical" style={{ height: '1rem', alignSelf: 'center', padding: 0 }} />
          </>
        )}
        {(epd.versionState === VersionStateType.Approved ||
          epd.versionState === VersionStateType.LicenseeAdminVerification ||
          epd.versionState === VersionStateType.DeclinedVerification ||
          epd.versionState === VersionStateType.UnderReview ||
          epd.versionState === VersionStateType.RegisteredEditorial) && (
          <>
            <EPDStatusBadge status={epd.versionState}>
              {epd.versionState === VersionStateType.DeclinedVerification
                ? 'Declined'
                : epd.versionState === VersionStateType.UnderReview
                ? 'Sent for verification'
                : epd.versionState === VersionStateType.LicenseeAdminVerification ||
                  epd.versionState === VersionStateType.RegisteredEditorial
                ? 'Approved'
                : epd.versionState}
            </EPDStatusBadge>
            {isMenuEnabled && <Divider layout="vertical" style={{ height: '1rem', alignSelf: 'center', padding: 0 }} />}
          </>
        )}

        {isMenuEnabled && (
          <>
            <Menu model={items} popup ref={menu} />
            <Button className="border-none surface-section text-gray-900 px-0" onClick={(e) => menu.current?.toggle(e)}>
              <i className="pi pi-ellipsis-v font-bold " />
            </Button>
          </>
        )}
      </div>

      <Dialog
        header={'Edit EPD'}
        visible={isEditConfirmOpened}
        style={{ width: '50vw' }}
        onHide={() => setIsEditConfirmOpened(false)}
        footer={
          <>
            <SecondaryButton onClick={() => setIsEditConfirmOpened(false)} label="Cancel" />
            <PrimaryButton
              disabled={!declinedPublicationEditChoice}
              onClick={() => {
                if (declinedPublicationEditChoice === '1') {
                  onAcceptUpdateWithVerification();
                } else {
                  onAcceptEditorialUpdate();
                }
              }}
              label="Submit"
            />
          </>
        }
        closable={false}
      >
        <DialogContentContainer>
          <HelpBox>
            <span style={{ fontWeight: '700' }}>This EPD was verified and approved by the verifier.</span>
            <span>
              You are allowed to make editorial changes to a published EPD, such as correction of spelling errors without
              verification.
            </span>
            <span>
              If you need to update the EPD or LCA documents, you shall sent your EPD for verification again. The EPD status
              “Approved” will be reset.
            </span>
          </HelpBox>

          <CheckBoxPanel>
            <RadioButtonStyled
              inputId={epd.id + 'rb1'}
              value="1"
              name="editMode"
              checked={declinedPublicationEditChoice === '1'}
              onChange={(e) => {
                setDeclinedPublicationEditChoice(e.value);
              }}
            />
            <SmartLabel
              htmlFor={epd.id + 'rb1'}
              label="The EPD needs a verification, the documents will be updated."
              labelStyle={{ textTransform: 'none', marginLeft: '0.5rem' }}
            />
          </CheckBoxPanel>
          <CheckBoxPanel style={{ marginTop: '0.5rem' }}>
            <RadioButtonStyled
              inputId={epd.id + 'rb2'}
              value="2"
              name="editMode"
              checked={declinedPublicationEditChoice === '2'}
              onChange={(e) => {
                setDeclinedPublicationEditChoice(e.value);
              }}
            />
            <SmartLabel
              htmlFor={epd.id + 'rb2'}
              label="The EPD verification is not needed, I will make the editorial changes."
              labelStyle={{ textTransform: 'none', marginLeft: '0.5rem' }}
            />
          </CheckBoxPanel>
        </DialogContentContainer>
      </Dialog>
    </>
  );
};

const RadioButtonStyled = styled(RadioButton)`
  &.p-radiobutton .p-radiobutton-box.p-highlight,
  &.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: ${(props) => props.theme.colors.primaryWhite};
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }
  &.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.2rem rgba(231, 81, 19, 0.5);
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }
  &.p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-icon {
    background-color: ${(props) => props.theme.colors.primaryOrange};
  }
`;

const DialogContentContainer = styled.div`
  padding: 0 2rem 2rem 2rem;
  overflow-y: auto;
`;

export default HeaderActionPanel;
