import { EPDClient, RegisterEditorialModel, FileParameter } from './EpdClient';

const client = new EPDClient(process.env.REACT_APP_API_URL);

class EpdStateService {
  static delete = async (epdId: string | undefined) => {
    if (!epdId) {
      return;
    }

    const result = await client.delete(epdId);
    return result;
  };

  static cancelUpdate = async (epdId: string | undefined) => {
    if (!epdId) {
      return;
    }

    const result = await client.cancelUpdate(epdId);
    return result;
  };

  static startVerification = async (epdId: string | undefined) => {
    if (!epdId) {
      return;
    }
    const result = await client.startVerification(epdId);
    return result;
  };

  static cancelVerification = async (epdId: string | undefined) => {
    if (!epdId) {
      return;
    }
    const result = await client.cancelVerification(epdId);
    return result;
  };

  static needsVerification = async (epdId: string | undefined) => {
    if (!epdId) {
      return;
    }
    const result = await client.needsVerification(epdId);
    return result;
  };

  static publish = async (epdVersionId: string | undefined, epdId: string | undefined) => {
    if (!epdId) {
      return null;
    }
    if (!epdVersionId) {
      return null;
    }
    const result = await client.publish(epdVersionId, epdId);
    return result;
  };

  static unpublish = async (epdId: string | undefined) => {
    if (!epdId) {
      return null;
    }
    const result = await client.unpublish(epdId);
    return result;
  };

  static register = async (epdId: string | undefined) => {
    if (!epdId) {
      return null;
    }
    const result = await client.register(epdId);
    return result;
  };

  static approve = async (epdId: string | undefined) => {
    return await EpdStateService.approveVerification(epdId, false);
  };

  static approveVerification = async (epdId: string | undefined, withoutEmailNotification: boolean) => {
    if (!epdId) {
      return;
    }
    const result = await client.approve(epdId, withoutEmailNotification);
    return result;
  };

  static declineVerification = async (
    epdId: string | undefined,
    withoutEmailNotification: boolean,
    reason: string | undefined
  ) => {
    if (!epdId) {
      return;
    }
    const result = await client.declineVerification(epdId, withoutEmailNotification, reason);
    return result;
  };

  static deregister = async (epdId: string | undefined) => {
    if (!epdId) {
      return;
    }

    const result = await client.deregister(epdId);
    return result;
  };

  static cancelDeregisteration = async (epdId: string | undefined) => {
    if (!epdId) {
      return;
    }
    const result = await client.cancelDeregistration(epdId);
    return result;
  };

  static unlock = async (epdId: string | undefined) => {
    if (!epdId) {
      return;
    }
    const result = await client.unlock(epdId);
    return result;
  };

  static getVersions = async (epdId: string) => {
    if (!epdId) {
      return;
    }
    const result = await client.getVersions(epdId);
    return result;
  };

  static approveEditorial = async (epdId: string) => {
    if (!epdId) {
      return;
    }
    const result = await client.approveEditorial(epdId);
    return result;
  };

  static registerEditorial = async (epdId: string, notes: string | undefined) => {
    if (!epdId) {
      return;
    }
    const registrationEditor: RegisterEditorialModel = { epdId, notes };
    const result = await client.registerEditorial(epdId, registrationEditor);
    return result;
  };

  static licenseeAdminVerificationApprove = async (epdId: string) => {
    if (!epdId) {
      return;
    }
    const result = await client.licenseeAdminVerificationApprove(epdId);
    return result;
  };
  static licenseeAdminVerificationDecline = async (epdId: string) => {
    if (!epdId) {
      return;
    }
    const result = await client.licenseeAdminVerificationDecline(epdId);
    return result;
  };

  static cancelDeclineVerification = async (epdId: string | undefined) => {
    if (!epdId) {
      return;
    }
    const result = await client.cancelDeclineVerification(epdId);
    return result;
  };

  static cancelApproveVerification = async (epdId: string | undefined) => {
    if (!epdId) {
      return;
    }
    const result = await client.cancelApproveVerification(epdId);
    return result;
  };

  static getEPDVerificationStatesModel = async (epdId: string | undefined) => {
    if (!epdId) {
      return;
    }
    const result = await client.getEPDVerificationStatesModel(epdId);
    return result;
  };
  static getAllEPDCopyGroupFiles = async (epdId: string | undefined) => {
    if (!epdId) {
      return;
    }
    const result = await client.getAllEPDCopyGroupFiles(epdId);
    return result;
  };
  static removeCopyGroupFile = async (copyGroupId: string | undefined, fileId: string | undefined) => {
    if (!copyGroupId || !fileId) {
      return;
    }
    const result = await client.removeCopyGroupFile(copyGroupId, fileId);
    return result;
  };
  static addCopyGroupFile = async (
    copyGroupId: string | undefined,
    propertyName: string | undefined,
    fileName: string,
    newValue: any
  ) => {
    if (!copyGroupId || !propertyName || !newValue || !fileName) {
      return;
    }
    const addFile: FileParameter = { data: newValue, fileName: fileName };
    const result = await client.addCopyGroupFile(copyGroupId, propertyName, addFile);
    return result;
  };
}

export default EpdStateService;
