import { FileRow } from 'components/v2/file-row/FileRow';
import { HelpBox } from 'components/v2/help-boxes';
import { REMOVEFILE } from 'constants/constants';
import { useEffect, useState } from 'react';
import { ActionState, EPDCopyGroupStatus, EPDModel, VerificationType, VersionStateType } from 'services/EpdClient';
import styled from 'styled-components';

type TProps = {
  epd: EPDModel;
  groupStatus?: EPDCopyGroupStatus;
  isVerifier?: boolean;
  onChangeEpd: any;
};

const EpdVerificationDescription = ({ epd, groupStatus, isVerifier, onChangeEpd }: TProps) => {
  const [versionState, setVersionState] = useState<string>(epd?.versionState ?? VersionStateType.Draft);
  const [isProcessCertificate, setIsProcessCertificate] = useState<boolean>(
    epd.verificationType == VerificationType.EPDProcessCertification
  );
  useEffect(() => {
    if (epd === undefined) {
      return;
    }

    setVersionState(epd.versionState ?? VersionStateType.Draft);
    setIsProcessCertificate(epd.verificationType == VerificationType.EPDProcessCertification);
  }, [epd]);

  const hasUploadedVerificationReports = () => {
    return (epd.verificationReports?.length ?? 0) > 0;
  };

  const hasUploadedProcessCertificates = () => {
    return (epd.processCertificates?.length ?? 0) > 0;
  };

  const getAllFiles = () => {
    if (!hasUploadedVerificationReports() && !hasUploadedProcessCertificates()) {
      return [];
    } else if (hasUploadedVerificationReports() && !hasUploadedProcessCertificates()) {
      return epd.verificationReports;
    } else if (!hasUploadedVerificationReports() && hasUploadedProcessCertificates()) {
      return epd.processCertificates;
    }
    return epd.verificationReports?.concat(epd.processCertificates || []);
  };

  return (
    <>
      {!isProcessCertificate && (
        <>
          {(((groupStatus == EPDCopyGroupStatus.DeclinedPublication ||
            groupStatus == EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin) &&
            versionState == VersionStateType.Draft) ||
            versionState == VersionStateType.Approved ||
            versionState == VersionStateType.LicenseeAdminVerification ||
            versionState == VersionStateType.RegisteredEditorial) && (
            <>
              {epd.verificationReports?.map((report: any) => (
                <FileRow
                  key={report.id}
                  file={report}
                  disabled
                  onRemoveFile={() => {
                    onChangeEpd(REMOVEFILE, report?.id);
                  }}
                />
              ))}
            </>
          )}
          {versionState == VersionStateType.DeclinedVerification && <DeclineReport>{epd?.declineReason}</DeclineReport>}
          {versionState == VersionStateType.UnderReview && hasUploadedVerificationReports() && (
            <>
              {epd.verificationReports?.map((report: any) => (
                <FileRow
                  key={report.id}
                  file={report}
                  disabled={epd.epdVerificationActionStates?.uploadVerificationReport == ActionState.Enabled}
                  onRemoveFile={() => {
                    onChangeEpd(REMOVEFILE, report?.id);
                  }}
                />
              ))}
            </>
          )}
          {versionState == VersionStateType.UnderReview && !hasUploadedVerificationReports() && (
            <>
              {isVerifier ? (
                <PendingReport>Please upload the verification report.</PendingReport>
              ) : (
                <PendingReport>No documents.</PendingReport>
              )}
            </>
          )}
        </>
      )}
      {isProcessCertificate && (
        <>
          {(hasUploadedVerificationReports() || hasUploadedProcessCertificates()) && (
            <>
              {getAllFiles()?.map((report: any) => (
                <FileRow
                  key={report.id}
                  file={report}
                  disabled={epd.epdVerificationActionStates?.uploadVerificationReport == ActionState.Enabled}
                  onRemoveFile={() => {
                    onChangeEpd(REMOVEFILE, report?.id);
                  }}
                />
              ))}
            </>
          )}
          {!hasUploadedVerificationReports() && !hasUploadedProcessCertificates() && (
            <PendingReport>Please upload a verification report and a process certificate if it is needed.</PendingReport>
          )}
        </>
      )}{' '}
    </>
  );
};

const DeclineReport = styled(HelpBox)`
  word-break: break-word;
  background-color: #f5d6d4;
  color: #e20238;
  flex-direction: row;
  padding: 14px 16px;
  align-items: center;
  margin: 10px 10px 10px 0px;
`;

const PendingReport = styled(HelpBox)`
  background-color: #f3f4fb;
  color: #151515;
  flex-direction: row;
  padding: 14px 16px;
  align-items: center;
  margin: 10px 10px 10px 0px;
`;

export default EpdVerificationDescription;
