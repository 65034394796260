import { StepStatus } from 'pages/wizard/types';
import { Panel } from 'primereact/panel';
import { ActionState, EPDCopyGroupStatus, VersionStateType } from 'services/EpdClient';
import styled, { css } from 'styled-components';

export const FormPanel = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 16px;
  padding: 30px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.04);
`;

export const FieldPanel = styled(FormPanel)<{ state?: ActionState }>`
  display: ${(props) => (props?.state === ActionState.Hidden ? 'none' : 'grid')};
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: start;

  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${(props) => props.theme.media.desktop} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const FieldPanelFullWidth = styled(FieldPanel)`
  grid-template-columns: 1fr;
`;

export const FieldContainer = styled.div`
  margin-top: 22px;
`;

export const FieldPanelInput = styled.div`
  width: 100%;
  padding: 0px;
  display: grid;
  align-self: start;
`;

export const HeaderCaptionSemiBold = styled.label`
  ${(props) => props.theme.typography.headerCaptionSemiBold}
`;

export const Step = styled.div`
  ${(props) => props.theme.typography.headerCaptionSemiBold}

  display: flex;
  flex-direction: row;
  padding: 1rem 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.lightGray};
  }
`;

export const CheckBoxPanel = styled.div`
  text-align: start;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem;
  width: 100%;
  background-color: rgba(231, 81, 19, 0.1);
  border-radius: 2px;
  height: 44px;
`;

export const EPDStatusBadge = styled.div<{ status?: VersionStateType }>`
  ${(props) => props.theme.typography.headerCaptionMedium};
  white-space: nowrap;
  height: 22px;
  padding: 0.25rem 0.75rem;
  border-radius: 2px;
  color: ${(props) => props.theme.colors.primaryBlack80};
  background-color: ${(props) => props.theme.colors.statusLabelGrayBg};

  ${(props) =>
    props.status === VersionStateType.UnderReview &&
    css`
      color: ${(props) => props.theme.colors.statusLabelYellowText};
      background-color: ${(props) => props.theme.colors.statusLabelYellowBg};
    `}

  ${(props) =>
    (props.status === VersionStateType.Approved ||
      props.status === VersionStateType.RegisteredEditorial ||
      props.status === VersionStateType.LicenseeAdminVerification) &&
    css`
      color: ${(props) => props.theme.colors.statusLabelGreenText};
      background-color: ${(props) => props.theme.colors.statusLabelGreenBg};
    `}

  ${(props) =>
    props.status === VersionStateType.DeclinedVerification &&
    css`
      color: ${(props) => props.theme.colors.statusLabelRedText};
      background-color: ${(props) => props.theme.colors.statusLabelRedBg};
    `}
`;

export const EPDGroupStatusBadge = styled.div<{ status?: EPDCopyGroupStatus }>`
  ${(props) => props.theme.typography.headerCaptionMedium};
  margin-left: 1.5rem;
  border-radius: 2px;
  display: inline-flex;
  height: 22px;
  padding: 4px 10px;
  align-items: flex-start;
  white-space: nowrap;

  color: ${(props) => props.theme.colors.primaryBlack80};
  background-color: ${(props) => props.theme.colors.statusLabelGrayBg};

  ${(props) =>
    (props.status === EPDCopyGroupStatus.SentForVerification ||
      props.status === EPDCopyGroupStatus.Verified ||
      props.status === EPDCopyGroupStatus.SentForPublication ||
      props.status === EPDCopyGroupStatus.ApprovedByLicenseeAdmin) &&
    css`
      color: ${(props) => props.theme.colors.statusLabelYellowText};
      background-color: ${(props) => props.theme.colors.statusLabelYellowBg};
    `}

  ${(props) =>
    (props.status === EPDCopyGroupStatus.DeclinedPublication ||
      props.status === EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin) &&
    css`
      color: ${(props) => props.theme.colors.statusLabelRedText};
      background-color: ${(props) => props.theme.colors.statusLabelRedBg};
    `}
`;

export const EPDGroupCardDashboardStatus = styled(EPDGroupStatusBadge)`
  font-style: italic;
  flex: 1 0 auto;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  ${(props) =>
    (props.status === EPDCopyGroupStatus.Draft || props.status === EPDCopyGroupStatus.Reserved) &&
    css`
      color: ${(props) => props.theme.colors.primaryWhite};
      background-color: ${(props) => props.theme.colors.darkGray};
    `}

  text-transform: none;
`;

export const InfoBox = styled.div`
  display: flex;
  padding: 1rem;
  border-left: solid 3px ${(props) => props.theme.colors.orange};
  margin: 0.5rem 0;
  background-color: #f3f3f3;
  margin-bottom: 3rem;

  @media print {
    display: none;
  }
`;

export const ErrorTextBox = styled.div`
  position: relative;
  border-radius: 4px;
  padding: 1rem 3rem 1rem 2rem;
  flex-shrink: 0;
  margin-bottom: 1rem;
  background-color: ${(props) => props.theme.colors.statusLabelRedBg};
  ${(props) => props.theme.typography.statusLabelsRedText}

  @media print {
    display: none;
  }
`;

export const MainViewFull = styled.div`
  flex: 0 0 100%;

  ${ErrorTextBox} {
    word-break: break-word;
  }
`;

export const HorizontalDivider = styled.div`
  margin: 30px 0 0 0;
  border-bottom: 2px solid ${(props) => props.theme.colors.elementsInputBorder};
`;

export const WizardHeaderGrid = styled.div`
  display: grid;
  width: 100%;
  margin-bottom: 40px;
  align-items: center;
  grid-template-columns: 35px 1fr auto;
`;

export const WizardHeaderButtonBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-left: 16px;
  justify-self: flex-end;
`;

export const StyledInput = styled.input`
  padding: 1rem;
  border: 1px solid
    ${(props) => (props.disabled ? props.theme.colors.elementsFilledInput : props.theme.colors.elementsInputBorder)};
  border-radius: 2px;
  margin: 0;
  height: 44px;

  &:focus {
    border-color: ${(props) => (props.disabled ? 'transparent' : props.theme.colors.primaryOrange)};
  }

  &:focus-visible {
    outline: 2px solid
      ${(props) => (props.disabled ? props.theme.colors.elementsInputBorder : props.theme.colors.primaryOrange)};
  }

  &:hover {
    border-color: ${(props) => (props.disabled ? 'transparent' : props.theme.colors.primaryOrange)};
  }

  background-color: ${(props) =>
    props.disabled ? props.theme.colors.elementsFilledInput : props.theme.colors.primaryWhite};
  color: ${(props) => (props.disabled ? props.theme.colors.primaryBlack70 : props.theme.colors.black)};

  ${(props) => props.theme.typography.bodyInput};
`;

export const EpdItemPanel = styled(Panel)`
  border-radius: 4px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.04);
  border: 0;
  .p-panel-header {
    border: 0;
    border-radius: 4px;
  }
  .p-panel-content {
    border: 0;
    margin-top: -4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: ${(props) => props.theme.colors.primaryWhite};
    padding-left: 5rem;
  }
`;

export const CheckBoxGroup = styled.div`
  max-height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .p-checkbox,
  .p-checkbox .p-checkbox-box {
    width: 16px;
    height: 16px;
  }
  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    font-size: 10px;
    font-weight: 700;
  }
  .p-checkbox-box.p-highlight.p-disabled {
    background-color: #757575;
    border-color: #757575;
  }
`;
