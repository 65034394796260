import 'primeflex/primeflex.css';

import { SpeedButton } from 'components/v2/buttons';
import { EPDCopyGroupStatus } from 'services/EpdClient';
import styled from 'styled-components';
import { EPDGroupStatusBadge } from 'styles/v2/Styles.styled';

type TProps = {
  title: string;
  status?: EPDCopyGroupStatus;
  backTo: string;
  onBack: any;
};


const TitleAndBack = ({ title, status, backTo, onBack }: TProps) => {
  const groupStatus = status === EPDCopyGroupStatus.ApprovedByLicenseeAdmin ? 'Sent for publication' 
    : status === EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin ? 'Declined publication' : status;
  return (
    <>
      <div style={{ width: '35px', marginRight: '16px', marginTop: '-10px' }}>
        <SpeedButton icon="pi pi-arrow-left" aria-label="Back" onClick={() => onBack()} />
        <BackText>{backTo}</BackText>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', marginTop: '-10px' }}>
        <TitleText style={{ minWidth: '5rem' }}>{title}</TitleText>
        {status && (
          <div>
            <EPDGroupStatusBadge status={status}>{groupStatus}</EPDGroupStatusBadge>
          </div>
        )}
      </div>
    </>
  );
};

const BackText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Primary/Black 70 */
  color: rgba(21, 21, 21, 0.7);
  opacity: 0.5;
  margin-left: 40px;
  position: absolute;
  margin-top: 5px;
`;

const TitleText = styled.div`
  ${(props) => props.theme.typography.headerH2}
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default TitleAndBack;
