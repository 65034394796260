import { CopyEpdV2Model, EPDClient, UpdateEPDGroupPropertyModel } from './EpdClient';

const client = new EPDClient(process.env.REACT_APP_API_URL);

class CopyEpdService {
  static getEpdToCopyDataSources = async (accountId: string, companyId: string) => {
    return await client.getEpdToCopyDataSources(accountId, companyId);
  };

  static copyEpd = async (model: CopyEpdV2Model) => {
    const result = await client.createEpdCopies(model);
    return result;
  };

  static copySingleEpd = async (model: CopyEpdV2Model) => {
    const result = await client.createEpdSingleCopy(model);
    return result;
  };

  static getEpdGroup = async (id: string) => {
    if (!id) {
      return undefined;
    }

    const result = await client.getEpdGroup(id);
    return result;
  };

  static updateEpdGroupProperty = async (copyGroupId: string, propertyName: string, newValue: any) => {
    const updateGroup: UpdateEPDGroupPropertyModel = { copyGroupId, propertyName, newValue };
    const result = await client.updateCopyEPDGroup(updateGroup);
    return result;
  };

  static getEpdDataToCopyAllDataset = async (id: string) => {
    if (!id) {
      return undefined;
    }

    const result = await client.getEpdDataToCopyAllDataset(id);
    return result;
  };

  static deleteEpdCopyGroup = async (id: string) => {
    if (!id) {
      return undefined;
    }

    const result = await client.deleteEpdCopyGroup(id);
    return result;
  };

  static addNewEpdToEpdCopyGroup = async (id: string) => {
    if (!id) {
      return undefined;
    }

    const result = await client.addNewEpdToEpdCopyGroup(id);
    return result;
  };

  static unlinkEpdFromCopyGroup = async (groupId: string, epdVersionId: string) => {
    if (!groupId || !epdVersionId) {
      return undefined;
    }

    const result = await client.unlinkEpdFromCopyGroup(groupId, epdVersionId);
    return result;
  };

  static editApprovedWithVerification = async (id: string) => {
    if (!id) {
      return undefined;
    }

    const result = await client.editApprovedWithVerification(id);
    return result;
  };

  static editApprovedEditorial = async (id: string) => {
    if (!id) {
      return undefined;
    }

    const result = await client.editApprovedEditorial(id);
    return result;
  };

  static deleteEpdFromCopyGroup = async (groupId: string, epdVersionId: string) => {
    if (!groupId || !epdVersionId) {
      return undefined;
    }

    const result = await client.deleteEpdFromCopyGroup(groupId, epdVersionId);
    return result;
  };

  static startGroupVerification = async (groupId: string | undefined) => {
    if (!groupId) {
      return;
    }

    const result = await client.startGroupVerification(groupId);
    return result;
  };

  static cancelGroupVerification = async (groupId: string | undefined) => {
    if (!groupId) {
      return;
    }

    const result = await client.cancelGroupVerification(groupId);
    return result;
  };

  static confirmGroupVerification = async (groupId: string | undefined) => {
    if (!groupId) {
      return;
    }

    const result = await client.confirmGroupVerification(groupId);
    return result;
  };

  static sendGroupToPublish = async (groupId: string | undefined) => {
    if (!groupId) {
      return;
    }

    const result = await client.sendGroupToPublish(groupId);
    return result;
  };

  static approveByLicenseeAdmin = async (groupId: string | undefined) => {
    if (!groupId) {
      return;
    }

    const result = await client.approveGroupByLicenseeAdmin(groupId);
    return result;
  };

  static declineGroup = async (groupId: string | undefined, declineReason: string | undefined) => {
    if (!groupId) {
      return;
    }

    const result = await client.declineGroup(groupId, declineReason);
    return result;
  };

  static declineGroupByLicenseeAdmin = async (groupId: string | undefined, declineReason: string | undefined) => {
    if (!groupId) {
      return;
    }

    const result = await client.declineGroupByLicenseeAdmin(groupId, declineReason);
    return result;
  };

  static publishGroup = async (groupId: string | undefined) => {
    if (!groupId) {
      return;
    }

    const result = await client.publishGroup(groupId);
    return result;
  };

  static getEPDGroupState = async (groupId: string) => {
    if (!groupId) {
      return undefined;
    }

    const result = await client.getEPDGroupState(groupId);
    return result;
  };
}

export default CopyEpdService;
