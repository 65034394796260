export const PoorMansError = (errors: any): string => {
  //Server errors are inconsistent. Try parse response as errors

  if (typeof errors === 'string') {
    try {
      const object = JSON.parse(errors);
      return PoorMansError(object);
    } catch {
      return errors;
    }
  }

  if (!errors) {
    return '';
  }

  if (errors.detail) {
    return errors.detail;
  }

  if (errors.title) {
    return errors.title;
  }

  if (errors.errorMessage) {
    return errors.errorMessage;
  }

  if (errors.ErrorMessage) {
    return errors.ErrorMessage;
  }

  if (errors.response) {
    return PoorMansError(errors.response);
  }

  const getErrorFromArray = (errorsList: any): string => {
    if (errorsList.length > 0) {
      if (Array.isArray(errorsList[0])) {
        return PoorMansError(errorsList[0][0]);
      }

      return PoorMansError(errorsList[0]);
    }

    return '';
  };

  if (errors.Errors) {
    const errorsList = Object.values(errors.Errors);
    return getErrorFromArray(errorsList);
  }

  const values = errors.errors ? Object.values(errors.errors) : Object.values(errors);
  return getErrorFromArray(values);
};

export const generateExpireTime = () => {
  let date = new Date();
  date.setMinutes(date.getMinutes() + 600);
  date = new Date(date);
  return date.getTime();
};

export function formatDate(field?: Date | string) {
  if (field == null) return undefined;
  // The date picker expects the format yyyy-mm-dd according to the HTML specifications
  // and the swedish format happens to be just yyyy-mm-dd.
  return Intl.DateTimeFormat('sv-SE').format(field instanceof Date ? field : new Date(field));
}

export function formatDateTime(field?: Date | string) {
  const options: {
    year: string;
    month: string;
    day: string;
    hour: string;
    minute: string;
    hour12: boolean;
  } = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };
  if (field == null) return undefined;
  // The date picker expects the format yyyy-mm-dd according to the HTML specifications
  // and the swedish format happens to be just yyyy-mm-dd.
  return Intl.DateTimeFormat('sv-SE', options as any).format(field instanceof Date ? field : new Date(field));
}
