import ConfirmModal from 'components/modals/ConfirmModal';
import { AddImageIcon, DeleteImageIcon } from 'components/v2/icons';
import { REMOVEFILE } from 'constants/constants';
import removeIcon from 'images/icons/svg/error.svg';
import { UploadButton } from 'pages/wizard/WizardStep2';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileModelPlus } from 'services/EpdClient';
import FileService from 'services/FileService';
import styled from 'styled-components';
import { ButtonSmall } from 'styles/Styles.styled';

interface INoImages {
  onUpload: (e: any) => any;
  disabled?: boolean;
}

export const NoImages: React.FunctionComponent<INoImages> = ({ onUpload, disabled }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [dragId, setDragId] = useState<any>(null);
  const [previewImages, setPreviewImages] = useState<PImg[] | undefined>(undefined);

  interface PImg {
    id: number;
    imgId?: string;
    imgName?: string | undefined;
    epdFileId?: string;
  }

  const closeDeleteModal = () => {
    setIsModalOpen(false);
  };

  const deleteImage = (img: any) => {
    setIsModalOpen(true);
    setSelectedImage(img);
  };

  const handleOver = (ev: any) => {
    ev.preventDefault();
  };

  const handleDrag = (ev: any) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev: any) => {
    ev.preventDefault();
    const dragImage = previewImages?.find((image) => image.id.toString() === dragId);
    const dropImage = previewImages?.find((image) => image.id.toString() === ev.currentTarget.id);
    if (dragImage && dropImage) {
      const arr = moveItem(dragImage?.id - 1, dropImage?.id - 1);

      setPreviewImages(arr);
    }
  };

  const moveItem = (from: number, to: number) => {
    const f = previewImages?.splice(from, 1)[0];
    if (f) {
      previewImages?.splice(to, 0, f);
    }

    return previewImages?.map((img, index) => {
      return { id: index + 1, imgId: img.imgId, imgName: img.imgName, epdFileId: img.epdFileId } as PImg;
    });
  };

  return (
    <NoImagesPanel disabled={disabled}>
      <ImagesIcon className="pi pi-images" disabled={disabled} />
      {disabled ? (
        <p>EPD images will be displayed here</p>
      ) : (
        <>
          <MainText>
            Drag and drop or
            <UploadButton>
              &nbsp;<u>choose files</u>&nbsp;
              <input type="file" accept=".bmp,.jpeg,.jpg,.png" name={''} onChange={onUpload} disabled={disabled} />
            </UploadButton>
            to upload.
          </MainText>
          <HelpText>
            Image formats: <SmallHelpText>.png, .jpg.</SmallHelpText>
          </HelpText>
          <HelpText>
            Image size: <SmallHelpText>up to 1MB.</SmallHelpText>
          </HelpText>
        </>
      )}
    </NoImagesPanel>
  );
};

const ImagesIcon = styled.i<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? props.theme.colors.primaryBlack50 : props.theme.colors.secondaryLightGreen)};
  font-size: 20px;
`;

const MainText = styled.span`
  padding: 8px;
  display: flex;
  align-items: start;
`;

const HelpText = styled.span`
  color: ${(props) => props.theme.colors.primaryBlack50};
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 160% */
  letter-spacing: -0.2px;
`;

const SmallHelpText = styled(HelpText)`
  font-weight: 400;
`;

const NoImagesPanel = styled.div<{ disabled?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-items: center;
  align-self: center;
  color: ${(props) => (props.disabled ? props.theme.colors.primaryBlack50 : props.theme.colors.primaryGreen)};
  font-weight: ${(props) => (props.disabled ? 400 : 500)};
`;

export default INoImages;
