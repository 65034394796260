import { ProcessStatus } from 'constants/constants';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { useTranslation } from 'react-i18next';
import { PCRModel } from 'services/EpdClient';
import FileService from 'services/FileService';
import styled from 'styled-components';
import { A } from 'styles/Styles.styled';
import { Option } from 'types/types';

import SelectFormField from '../form/SelectFormField';

interface TProps {
  productCategoryList: Option[];
  productCategory: Option | undefined;
  pcrList: PCRModel[];
  pcr: PCRModel | undefined;
  status: ProcessStatus;
  onProductCategorySelect: any;
  onPcrSelect: any;
}

const PcrSelectorDetails = ({
  productCategoryList,
  productCategory,
  pcrList,
  pcr,
  status,
  onProductCategorySelect,
  onPcrSelect,
}: TProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SelectFormField
        name={'productCategory'}
        options={productCategoryList}
        value={productCategory}
        isClearable
        onChange={(value: any) => {
          onProductCategorySelect(value);
        }}
        placeholder={t('epdWizard.wizardStep1.epdDeveloperMenu.placeholder.productCategory')}
      />

      <div style={{ margin: '0.5rem 0 1rem 0' }}>
        {status === ProcessStatus.Fetching && (
          <div style={{ padding: '1rem', fontStyle: 'italic' }}>{t('epdWizard.wizardStep1.pcrMenu.messages.fetchList')}</div>
        )}
        {status === ProcessStatus.Success &&
          (pcrList?.length > 0 ? (
            pcrList.map((item) => (
              <PcrListItem key={item.id} className={pcr?.id === item.id ? 'selected' : ''}>
                <PcrListItemHeader>
                  <span style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>{item.fullName}</span>
                  <RadioButtonStyled
                    inputId={item.id}
                    name="pcrItem"
                    checked={pcr?.id === item.id}
                    onChange={() => onPcrSelect(item)}
                  />
                </PcrListItemHeader>
                {item.cpCs && (
                  <div>
                    {item.cpCs.map((cpc) => (
                      <div style={{ padding: '0.5rem 0 0 0 ' }} key={cpc.id}>
                        <em>{cpc.code}</em> <span>{cpc.description}</span>
                      </div>
                    ))}
                  </div>
                )}
                {item.documents &&
                  item.documents?.length > 0 &&
                  item?.documents?.map(
                    (file, index) =>
                      file && (
                        <div key={index}>
                          <div style={{ padding: '0.5rem 0 0 0 ' }}>
                            <span>{file?.name || t('epdWizard.wizardStep1.pcrMenu.placeholderImage')}</span>
                          </div>
                          <A target="_blank" href={FileService.getImageUrl(file?.id || '')}>
                            <DownloadButton label="Download file" icon="pi pi-download" />
                          </A>
                        </div>
                      )
                  )}
              </PcrListItem>
            ))
          ) : (
            <div style={{ padding: '1rem', fontStyle: 'italic' }}>
              {'No items in this category or category is not selected'}
            </div>
          ))}
      </div>
    </>
  );
};

const PcrListItem = styled.div`
  padding: 1rem;
  margin-bottom: 0.25rem;
  cursor: auto;
  border: 1px solid ${(props) => props.theme.colors.elementsInputBorder};
  &.selected {
    background-color: ${(props) => props.theme.colors.elementsTableRow};
    border: none;
  }
`;

const PcrListItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RadioButtonStyled = styled(RadioButton)`
  &.p-radiobutton .p-radiobutton-box.p-highlight,
  &.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: ${(props) => props.theme.colors.primaryWhite};
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }
  &.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.2rem rgba(231, 81, 19, 0.5);
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }
  &.p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-icon {
    background-color: ${(props) => props.theme.colors.primaryOrange};
  }
`;

const DownloadButton = styled(Button)`
  margin-top: 0.5rem;
  padding: 0.25rem 0.5rem;
  ${(props) => props.theme.fonts.textSmall}
  .pi {
    font-size: 0.75rem;
  }
`;

export default PcrSelectorDetails;
