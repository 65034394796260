import { LabelHTMLAttributes } from 'react';

import { LabelGroup } from '../form/styled';
import { StatusAttentionIcon } from '../icons';
import TooltipHelpIcon from '../icons/TooltipHelpIcon';

export type TSmartLabelProps = {
  label: string | undefined;
  tooltip?: string | undefined;
  tooltipPosition?: any | undefined;
  labelStyle?: any;
  required?: boolean;
  hasValue?: boolean;
  htmlFor?: any;
  error?: any;
};

const SmartLabel = ({
  label,
  tooltip,
  tooltipPosition,
  required,
  hasValue,
  labelStyle,
  htmlFor,
  error,
}: TSmartLabelProps) => {
  return (
    <LabelGroup>
      <label htmlFor={htmlFor} style={labelStyle}>
        {label}
      </label>
      {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
      {(error || (required && !hasValue)) && <StatusAttentionIcon />}
    </LabelGroup>
  );
};

export default SmartLabel;
