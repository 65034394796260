import {
  CreatePCRModel,
  FileParameter,
  GeographicalScopesClient,
  PCRClient,
  PCRStatusSelection,
  PCRTypeSelection,
  ProductCategoriesClient,
  ProgramOperatorsClient,
  UpdatePCRPropertyModel,
} from './EpdClient';

const client = new PCRClient(process.env.REACT_APP_API_URL);
const productCategoryClient = new ProductCategoriesClient(process.env.REACT_APP_API_URL);
const geographicalClient = new GeographicalScopesClient(process.env.REACT_APP_API_URL);
const programOperatorsClient = new ProgramOperatorsClient(process.env.REACT_APP_API_URL);

class PcrService {
  static getPcrs = async (
    filter?: string,
    pCRStatusSelection?: PCRStatusSelection,
    pCRTypeSelection?: PCRTypeSelection,
    categoryId?: string
  ) => {
    return await client.getAll(filter, pCRStatusSelection, pCRTypeSelection, categoryId, undefined);
  };

  static findPcr = async (filter: string, categoryId?: string, isDisabled?: boolean | undefined) => {
    return await client.getAll(filter, PCRStatusSelection.Registered, undefined, categoryId, isDisabled);
  };

  static getPcr = async (filter: string, categoryId?: string) => {
    return await client.getAll(filter, PCRStatusSelection.Registered, undefined, categoryId, undefined);
  };

  static getOnePcr = async (id: string) => {
    return await client.get(id);
  };

  static getAllCategories = async () => {
    return await productCategoryClient.getAll('', false);
  };

  static getGeographicals = async () => {
    return await geographicalClient.getAll(null);
  };

  static createPcr = async () => {
    const newPcr: CreatePCRModel = {};
    const result = await client.create(newPcr);
    return result;
  };

  static updateProperty = async (pcrId: string, propertyName: string, newValue: any) => {
    const updatePcr: UpdatePCRPropertyModel = { pcrId, propertyName, newValue };
    const result = await client.updatePCRProperty(updatePcr);
    return result;
  };

  static addPcrFile = async (pcrId: string, propertyName: string, fileName: string, data: any) => {
    const addFile: FileParameter = { fileName, data };
    const result = await client.addPCRFile(pcrId, propertyName, addFile);
    return result;
  };

  static removePcrFile = async (pcrId: string, fileId: string) => {
    const result = await client.removePCRFile(pcrId, fileId);
    return result;
  };

  static getProgramOperators = async (filter?: string) => {
    const result = await programOperatorsClient.getAll(filter);
    return result;
  };
}

export default PcrService;
