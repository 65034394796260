import { EmptyFileHelpBox } from 'components/v2/help-boxes';
import { FileModel } from 'services/EpdClient';
import styled from 'styled-components';
import Spinner from 'styles/spinner-left.styled';
import FileRow from './FileRow';

type TProps = {
  files?: FileModel[];
  disabled?: boolean;
  onRemoveFile?: any;
  fileLoading?: boolean;
  title?: string;
};

const FilesList = ({ files, disabled, onRemoveFile, fileLoading, title }: TProps) => {
  if (!fileLoading && (!files || files.length === 0)) {
    return (
      <EmptyFileHelpBox>
        <Text>{title ?? 'No documents were added yet'}</Text>
      </EmptyFileHelpBox>
    );
  }

  return (
    <>
      {fileLoading && (
        <SpinnerRow>
          <Spinner inline />
        </SpinnerRow>
      )}
      {files?.map((f, index) => (
        <FileRow key={index} file={f} disabled={disabled} onRemoveFile={onRemoveFile} />
      ))}
    </>
  );
};

const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SpinnerRow = styled.div`
  flex-direction: row;
  padding: 14px 16px;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  ${Spinner} {
    width: 1rem;
    height: 1rem;
  }
`;

export default FilesList;
