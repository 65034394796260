import { Dialog } from 'primereact/dialog';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

type TProps = PropsWithChildren<{
  isOpened: boolean;
  header: string;
  onHide: any;
  maximizable?: boolean;
}>;

const PcrSelectorFilesDialog = ({ isOpened, header, onHide, children, maximizable, ...props }: TProps) => {
  return (
    <DialogStyled
      header={header}
      visible={isOpened}
      style={{ width: '40vw' }}
      onHide={onHide}
      footer={null}
      maximizable={maximizable}
      {...props}
    >
      <DialogContentContainer>{children}</DialogContentContainer>
    </DialogStyled>
  );
};

const DialogStyled = styled(Dialog)`
  ${(props) => props.theme.fonts.textSmall}
  .p-dialog-header .p-dialog-title {
    font-weight: 700;
    padding: 0.75rem 0.5rem 1rem 1rem;
    ${(props) => props.theme.fonts.textSmall}
  }
`;

const DialogContentContainer = styled.div`
  padding: 0.25rem 2rem 2rem 2rem;
`;

export default PcrSelectorFilesDialog;
