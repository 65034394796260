import { IlcdEpdMappingModel } from 'pages/admin/integrations/ilcdEpd/types';
import {
  AdminCompaniesClient,
  EPDClient,
  AdminCountriesClient,
  AdminRolesClient,
  PublicInformationMessagesClient,
  AdminIntegrationsClient,
  FortnoxIntegrationsClient,
} from '../EpdClient';

const companiesClient = new AdminCompaniesClient(process.env.REACT_APP_API_URL);
const rolesClient = new AdminRolesClient(process.env.REACT_APP_API_URL);
const countriesClient = new AdminCountriesClient(process.env.REACT_APP_API_URL);
const publicInformationMessagesClient = new PublicInformationMessagesClient(process.env.REACT_APP_API_URL);
const integrationsClient = new AdminIntegrationsClient(process.env.REACT_APP_API_URL);
const epdClient = new EPDClient(process.env.REACT_APP_API_URL);
const fortnoxIntegrationsClient = new FortnoxIntegrationsClient(process.env.REACT_APP_API_URL);

class AdminService {
  static getCompanies = async (companyId?: string, roleId?: string, includeArchived?: boolean, onlyHolding?: boolean) => {
    return await companiesClient.getAll(companyId, roleId, includeArchived, onlyHolding);
  };

  static getCompany = async (companyId?: string) => {
    return await companiesClient.get(companyId as string);
  };

  static getRoles = async () => {
    return await rolesClient.get();
  };

  static getCountries = async (smartFilter: string | null | undefined) => {
    return await countriesClient.get(smartFilter);
  };

  static getLinkableCompanies = async (companyId?: string) => {
    return await companiesClient.getLinkableCompanies(companyId);
  };

  static getHoldingableCompanies = async (companyId?: string) => {
    return await companiesClient.getHoldingableCompanies(companyId);
  };

  static setHoldingCompany = async (childCompanyId: string, parentCompanyId?: string) => {
    if (parentCompanyId === undefined || parentCompanyId === null) {
      parentCompanyId = '00000000-0000-0000-0000-000000000000';
    }
    return await companiesClient.setHoldingCompany(childCompanyId, parentCompanyId);
  };

  static updateCompany = async (company: any) => {
    return company.id && (await companiesClient.update(company.id, company)); // TODO: Fix when id is required
  };

  static getPublicInformationMessage = async () => {
    return await publicInformationMessagesClient.get();
  };
  static findIlcdEpdIntegrations = async (name?: string, environdecType?: string, ilcdType?: string) => {
    return await integrationsClient.find(name, environdecType, ilcdType);
  };

  static createIlcdEpdMapping = async (epdVersionId?: string) => {
    return await integrationsClient.create(epdVersionId || '');
  };

  static getIlcdEpdMapping = async (id: string) => {
    return await integrationsClient.get(id);
  };

  static updateIlcdEpdMapping = async (data: IlcdEpdMappingModel) => {
    const updateModel = {
      epdilcdRelationId: data.epdilcdRelationId || '',
      ilcdVersion: data.ilcdVersion,
      ilcdPlusEPDType: data.ilcdPlusEPDType?.label,
      environdecType: data.environdecType?.label,
    };
    return await integrationsClient.update(updateModel.epdilcdRelationId, updateModel);
  };

  static PublishIlcdEpd = async (id?: string) => {
    return await integrationsClient.publish(id || '');
  };

  static UnpublishIlcdEpd = async (id?: string) => {
    return await integrationsClient.unpublish(id || '');
  };

  static UploadIlcdEpdDataArchive = async (id: string) => {
    return await integrationsClient.uploadIlcdEpdDataArchive(id);
  };

  static GetILCDRelation = async (id: string) => {
    return await epdClient.getILCDRelation(id);
  };

  static GetILCDEPDMappingList = async (id: string) => {
    return await epdClient.getILCDRelationEPDMappingList(id);
  };

  static GetFortnoxSettings = async () => {
    return await fortnoxIntegrationsClient.getFortnoxSettings();
  };

  static UpdateAuthCode = async (code: string, state: string) => {
    return await fortnoxIntegrationsClient.updateAuthCode(code, state);
  };

  static PushAllCompaniesToFortnox = async () => {
    return await fortnoxIntegrationsClient.pushAllCompaniesToFortnox();
  };

  static GenerateNewTokens = async (code: string) => {
    return await fortnoxIntegrationsClient.generateNewTokens(code);
  };

  static GetLastLogs = async () => {
    return await fortnoxIntegrationsClient.getLastLogs();
  };

  static CreateInvoices = async (from: Date, to: Date) => {
    return await fortnoxIntegrationsClient.createInvoices(from, to);
  };

  static CreateInvoiceForSpecificCompany = async (from: Date, to: Date, companyId: string) => {
    return await fortnoxIntegrationsClient.createInvoiceForSpecificCompany(from, to, companyId);
  };
}

export default AdminService;
