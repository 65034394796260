import SmartLabel from 'components/v2/labels/SmartLabel';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import CalendarIcon from './CalendarIcon';
import styled from 'styled-components';

const DatePicker: React.FunctionComponent<{
  label: string;
  tooltip: string;
  initialDate: any | undefined;
  required?: boolean;
  tooltipPosition?: any;
  labelStyle?: any;
  onChange?: any;
}> = ({ 
  label, 
  labelStyle, 
  required, 
  tooltip, 
  tooltipPosition, 
  initialDate,  
  onChange }) => {
  return (
    <div>
      {label && (
        <SmartLabel
          label={label}
          required={required}
          labelStyle={labelStyle}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={initialDate}
        />
      )}
      <CalendarStyles>
        <Calendar
          dateFormat="dd-mm-yy"
          value={initialDate}
          placeholder="Select"
          onChange={onChange}
          icon={CalendarIcon}
          showIcon
        />
      </CalendarStyles>
    </div>
  );
};

const CalendarStyles = styled.div`
  .p-button {
    background-color: transparent;
    color: ${(props) => props.theme.colors.primaryBlack};
    border: none;
  }

  .p-button:hover {
    background-color: transparent;
    color: ${(props) => props.theme.colors.primaryBlack};
  }

  .p-inputtext {
    border: none;
  }

  .p-inputtext:enabled:focus {
    box-shadow: none;
    outline: none;
  }

  .p-inputwrapper {
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.elementsInputBorder};
    border-radius: 2px;
    &:hover {
      border-color: ${(props) => props.theme.colors.primaryOrange};
    }
  }

  .p-inputwrapper-focus {
    border: 1px solid ${(props) => props.theme.colors.primaryOrange};
    outline: 2px solid ${(props) => props.theme.colors.primaryOrange};
    border-radius: 2px;
  }
`;

export default DatePicker;
