import HelpIcon from 'components/icons/HelpIcon';
import helpIcon from 'images/icons/svg/info.svg';
import warningIcon from 'images/icons/svg/warning.svg';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';
import { ActionState, EPDModel, EPDStatus, LicenseType } from 'services/EpdClient';
import styled from 'styled-components';
import { useTheme } from 'styled-components';
import { A, ButtonSmall, H3 } from 'styles/Styles.styled';
import { Theme } from 'styles/theme';
import { formatDate } from 'util/utils';

import { Validation } from './Validation';
import { FixedLabel, FixedLabelTop, FixedRow, FixedTextInput, SelectWrapper } from './styles';
import { WizardStepValidationSchema } from './types';

type LicenseTypeOption = {
  label: LicenseType;
  value: LicenseType | undefined;
};

const WizardStep4: React.FunctionComponent<{
  epd?: EPDModel;
  onChangeEpd?: any;
  registerConsent: (val: boolean) => void;
  validation: WizardStepValidationSchema;
}> = ({ epd, onChangeEpd, registerConsent, validation }) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;

  let licenseTypeOptions: any[] = [];
  for (let s in LicenseType) {
    licenseTypeOptions.push({ value: s, label: LicenseType[s as keyof typeof LicenseType] });
  }

  const [checkboxStatus, setCheckboxStatus] = useState<Boolean>(false);
  let statusOptions: any[] = [];
  for (let s in EPDStatus) {
    statusOptions.push({ value: s, label: EPDStatus[s as keyof typeof EPDStatus] });
  }

  const changeLicenseType = (license: SingleValue<LicenseTypeOption>) => {
    if (license) {
      const selectedLicense = license.value;
      onChangeEpd('LicenseType', selectedLicense);
    }
  };

  const onConfirmClimate = () => {
    onChangeEpd('climateDeclaration', checkboxStatus);
  };

  return (
    <div>
      <FixedRow state={epd?.publicationDateState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep4.properties.publicationDate')}
          <HelpIcon content={t('epdWizard.wizardStep4.tooltips.publicationDate')} />
        </FixedLabel>
        {epd?.publicationDateState.actionState !== ActionState.Enabled ? (
          <em>{formatDate(epd?.publicationDate)}</em>
        ) : (
          <FixedTextInput
            value={formatDate(epd?.publicationDate)}
            type={'date'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              e.currentTarget.value !== formatDate(epd?.publicationDate) &&
              onChangeEpd('publicationDate', new Date(e.currentTarget.value))
            }
          />
        )}
      </FixedRow>
      <FixedRow state={epd?.publicVersionDateState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep4.properties.revisionDate.title')}
          <HelpIcon content={t('epdWizard.wizardStep4.tooltips.versionDate')} />
          <Validation validation={validation} epd={epd} field="publicVersionDate" />
        </FixedLabel>
        {epd?.publicVersionDateState.actionState !== ActionState.Enabled ? (
          <em>{formatDate(epd?.publicVersionDate)}</em>
        ) : (
          <FixedTextInput
            value={formatDate(epd?.publicVersionDate)}
            type={'date'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              e.currentTarget.value !== formatDate(epd?.publicVersionDate) &&
              onChangeEpd('publicVersionDate', new Date(e.currentTarget.value))
            }
          />
        )}
      </FixedRow>
      <FixedRow state={epd?.validUntilState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep4.properties.validityDate')}
          <HelpIcon content={t('epdWizard.wizardStep4.tooltips.validUntil')} />
          <Validation validation={validation} epd={epd} field="validUntil" />
        </FixedLabel>
        {epd?.validUntilState.actionState !== ActionState.Enabled ? (
          <em>{formatDate(epd?.validUntil)}</em>
        ) : (
          <>
            <FixedTextInput
              value={formatDate(epd?.validUntil)}
              type={'date'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.currentTarget.value !== formatDate(epd?.validUntil) &&
                  onChangeEpd('validityDate', new Date(e.currentTarget.value));
              }}
            />
          </>
        )}
      </FixedRow>
      <FixedRow state={epd?.epdOwnerState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep4.properties.epdOwner')}
          <HelpIcon content={t('epdWizard.wizardStep4.tooltips.contact')} />
        </FixedLabel>
        <Value>
          {epd?.epdOwner?.companyName} <br />
          {epd?.epdOwner?.contactName} {epd?.epdOwner?.contactEmail}
        </Value>
      </FixedRow>
      <FixedRow state={epd?.epdOwnerState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep4.properties.epdOwnerHomeCountry')}
          <HelpIcon content={t('epdWizard.wizardStep4.tooltips.homeCountry')} />
        </FixedLabel>
        <Value>{epd?.epdOwner?.companyHomeCountry}</Value>
      </FixedRow>
      <FixedRow state={epd?.isProtectedByCopyrightState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep4.properties.copyRight')}
          <HelpIcon content={t('epdWizard.wizardStep4.tooltips.copyright')} />
        </FixedLabel>
        <Value>
          {epd?.isProtectedByCopyright
            ? t('epdWizard.wizardStep4.properties.protected')
            : t('epdWizard.wizardStep4.properties.unprotected')}
        </Value>
      </FixedRow>
      <FixedRow state={epd?.licenseTypeState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep4.properties.licenseType')}
          <HelpIcon content={t('epdWizard.wizardStep4.tooltips.licenseType')} />
        </FixedLabel>
        <SelectWrapper>
          <Select
            onChange={(e) => changeLicenseType(e)}
            isDisabled={epd?.licenseTypeState?.actionState === ActionState.Disabled}
            options={licenseTypeOptions}
            styles={customSelectStyles}
            defaultValue={{
              value: epd?.licenseType,
              label: LicenseType[epd?.licenseType as unknown as keyof typeof LicenseType],
            }}
          />
        </SelectWrapper>
      </FixedRow>
      <FixedRow state={epd?.modifiedAtState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep4.properties.timeStamp')}
          <HelpIcon content={t('epdWizard.wizardStep4.tooltips.timeStamp')} />
        </FixedLabel>
        <Value>{formatDate(epd?.modifiedAt)}</Value>
      </FixedRow>
      <FixedRow state={epd?.statusState.actionState}>
        <FixedLabel>{t('epdWizard.wizardStep4.properties.status')}</FixedLabel>
        <Value>{epd?.status}</Value>
      </FixedRow>
      <FixedRow state={epd?.epdState?.register}>
        <FixedLabelTop>{t('epdWizard.wizardStep4.properties.confirmation.title')}</FixedLabelTop>
        <div>
          <InfoBox style={{ borderLeft: 'solid 3px green' }}>
            <img src={warningIcon} alt="confirmation" style={{ height: '35px', marginRight: '1rem', marginTop: '1rem' }} />
            <div>
              {theme.serviceAgreementUrl !== '/EPD-Australasia-fees-terms-conditions-1-April-2023.pdf' && (
                <p style={{ backgroundColor: 'yellow', color: 'red' }}>
                  We have updated the service agreement! Please read through the updated document before agreeing to the
                  Service agreement.
                </p>
              )}
              <p>{t('epdWizard.wizardStep4.properties.confirmation.info1')}</p>
              <p>{t('epdWizard.wizardStep4.properties.confirmation.info2')}</p>
              <p>
                <strong>{t('epdWizard.wizardStep4.properties.confirmation.infoFees')}</strong>
              </p>
              <p>
                <A target="_blank" rel="noopener noreferrer" href={theme.serviceAgreementUrl}>
                  {t('epdWizard.wizardStep4.properties.confirmation.serviceAgreement')}
                </A>
              </p>
              <p>
                <label style={{ fontWeight: 600, fontSize: '18px' }}>
                  <Input
                    type="checkbox"
                    style={{ margin: '0.5rem 10px 1rem 0' }}
                    defaultChecked={epd?.serviceAgreementStatus ?? false}
                    disabled={epd?.epdState?.register === ActionState.Disabled}
                    onChange={(e) => {
                      registerConsent(e.target.checked);
                    }}
                  />{' '}
                  {t('epdWizard.wizardStep4.properties.confirmation.iAgree')}
                </label>
              </p>
            </div>
          </InfoBox>
        </div>
      </FixedRow>
      {/* <FixedRow>
        <FixedLabel>Eco platform registration number</FixedLabel>
        <FixedTextInput value={epd?.ecoPlatformRegistrationNumber} />
      </FixedRow>
      <FixedRow>
        <FixedLabel>Climate declaration based on the EPD</FixedLabel>
        <input type="checkbox" style={{ margin: '0.5rem 0 1rem 0' }} />
      </FixedRow>
      <FixedRow>
        <FixedLabel>Use the ECO Platform logo and reference number in the EPD</FixedLabel>
        <input type="checkbox" style={{ margin: '0.5rem 0 1rem 0' }} />
      </FixedRow> */}
      <H3 style={{ marginTop: '2rem' }}></H3> {/* Additional services */}
      {epd?.climateDeclarationState?.actionState !== ActionState.Disabled &&
        epd?.climateDeclarationConfirmationState.actionState !== ActionState.Hidden && (
          <FixedRow>
            <FixedLabelTop>{t('epdWizard.wizardStep4.properties.climateDeclaration.title')}</FixedLabelTop>
            <input
              type="checkbox"
              style={{ margin: '0.5rem 0 1rem 0' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCheckboxStatus(e.currentTarget.checked)}
            />
          </FixedRow>
        )}
      {epd?.climateDeclarationConfirmationState.actionState !== ActionState.Hidden ? (
        <>
          <FixedRow>
            <FixedLabel></FixedLabel>
            <InfoBox>
              <img src={helpIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
              <div>{t('epdWizard.wizardStep4.properties.climateDeclaration.info')}</div>
            </InfoBox>
          </FixedRow>
          <FixedRow>
            <FixedLabel></FixedLabel>
            <ButtonSmall onClick={onConfirmClimate}>
              {t('epdWizard.wizardStep4.properties.climateDeclaration.confirm')}
            </ButtonSmall>
          </FixedRow>
        </>
      ) : (
        <FixedRow>
          <FixedLabel> </FixedLabel> {/* Climate Declaration Order Date: */}
          <Value>
            <div style={{ color: 'green' }}>{formatDate(epd?.climateDeclarationOrderDate)}</div>
          </Value>
        </FixedRow>
      )}
    </div>
  );
};

export const customSelectStyles = {
  menu: (styles: any) => ({ ...styles }),
  control: (styles: any) => ({
    ...styles,
    borderRadius: 0,
    borderColor: 'gray',
    color: 'black',
    fontSize: '14px',
    minHeight: '49px',
    margin: '0.5rem 0 ',
    minWidth: '10rem',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontSize: '100%',
  }),
};

const Value = styled.em`
  margin: 0.5rem 0;
`;

export const InfoBox = styled.div`
  display: flex;
  padding: 1rem;
  border-left: solid 3px green;
  margin: 0.5rem 0;
  background-color: #f3f3f3;
`;

const Input = styled.input`
  -ms-transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(2);
  padding: 10px;
`;

export default WizardStep4;
