import {
  CopyEpdModel,
  CreateEPDModel,
  EPDClient,
  FileParameter,
  GeographicalScopesClient,
  MultiCopyEpdModel,
  UpdateEPDPropertyModel,
} from './EpdClient';

const client = new EPDClient(process.env.REACT_APP_API_URL);
const geographicalClient = new GeographicalScopesClient(process.env.REACT_APP_API_URL);

class EPDService {
  static getEpds = async (accountId: string, companyId?: string, smartFilter?: string, excludeCollectionId?: string) => {
    return await client.getAll(smartFilter, accountId, companyId, excludeCollectionId);
  };

  static getEpd = async (epdId: string) => {
    return await client.get(epdId);
  };

  static createEpd = async (accountId: string) => {
    const createEpd: CreateEPDModel = { accountId: accountId };
    const result = await client.create(createEpd);
    return result;
  };

  static copyEpd = async (epdId: string, accountId: string, newEpdName: string, copyProperties: any[]) => {
    const createEpd: CopyEpdModel = { epdId: epdId, accountId: accountId, newEpdName, copyProperties };
    const result = await client.createCopy(createEpd);
    return result;
  };

  static copyMultiEpd = async (model: MultiCopyEpdModel) => {
    const result = await client.createMultiCopy(model);
    return result;
  };

  static updateProperty = async (epdId: string, propertyName: string, newValue: any) => {
    const updateEpd: UpdateEPDPropertyModel = { epdId, propertyName, newValue };
    const result = await client.updateEPDProperty(updateEpd);
    return result;
  };

  static confirmUpdate = async (epdId: string, versionId: string) => {
    return client.confirmUpdate(epdId, versionId);
  };

  static updatePropertyWithVersion = async (epdId: string, versionId: string, propertyName: string, newValue: any) => {
    const updateEpd: UpdateEPDPropertyModel = { epdId, versionId, propertyName, newValue };
    const result = await client.updateEPDProperty(updateEpd);
    return result;
  };

  static removeEpdFile = async (epdFileId: string, epdVersionId: string) => {
    const result = await client.removeEPDFile(epdFileId, epdVersionId);
    return result;
  };

  static addEpdFile = async (epdId: string, propertyName: string, fileName: string, data: any) => {
    const addFile: FileParameter = { fileName, data };
    const result = await client.addEPDFile(epdId, propertyName, addFile);
    return result;
  };

  static arrangeEpdFilesByModifyDate = async (epdFileIds: string[]) => {
    const result = await client.arrangeEpdFilesByModifyDate(epdFileIds);
    return result;
  };

  static getLocations = async () => {
    const result = await geographicalClient.getAll(null);
    return result;
  };

  static connectDeveloper = async (epdId: string, membershipId: string) => {
    const result = await client.connectDeveloper(epdId, membershipId);
    return result;
  };

  static connectVerifier = async (epdId: string, membershipId: string) => {
    const result = await client.connectVerifier(epdId, membershipId);
    return result;
  };

  static getEpdGroups = async (accountId: string, companyId?: string, smartFilter?: string) => {
    return await client.getAllGroups(smartFilter, accountId, companyId);
  };

  static validateLCAFile = async (fileName: string, data: any) => {
    const file: FileParameter = { fileName, data };
    const result = await client.validateLCAFile(file);
    return result;
  };
}

export default EPDService;
