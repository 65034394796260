import { TextButton } from 'components/v2/buttons';
import WizardSelectComponent from 'components/v2/epd-wizard-inputs/WizardSelect';
import WizardTextAreaComponent from 'components/v2/epd-wizard-inputs/WizardTextArea';
import WizardTextInputComponent from 'components/v2/epd-wizard-inputs/WizardTextInput';
import { LabelGroup } from 'components/v2/form/styled';
import { HelpBox } from 'components/v2/help-boxes';
import SmartLabel from 'components/v2/labels/SmartLabel';
import { Divider } from 'primereact/divider';
import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DataSourceVersionsService from 'services/DataSourceVersionsService';
import DataSourcesService from 'services/DataSourcesService';
import {
  DataSourceModel,
  DataSourceVersionModel,
  EPDModel,
  FlowPropertyModel,
  MaterialPropertyModel,
  Subtype,
} from 'services/EpdClient';
import styled from 'styled-components';
import { FieldContainer, FieldPanelInput, HorizontalDivider } from 'styles/v2/Styles.styled';
import { Option } from 'types/types';

import EditDialog from './EditDialog';

type TProps = {
  isOpened: boolean;
  onHide: any;
  epd?: EPDModel;
  onChangeEpd: any;
  flowProperties: FlowPropertyModel[];
  materialProperties: MaterialPropertyModel[];
  isConstruction?: boolean;
};

const EditMachineReadableDialog = ({
  isOpened,
  onHide,
  epd,
  onChangeEpd,
  flowProperties,
  materialProperties,
  isConstruction,
}: TProps) => {
  const { t } = useTranslation();
  const [flowPropertyOption, setFlowPropertyOption] = useState<{
    value: string | undefined;
    label: string | undefined;
  }>({ value: undefined, label: undefined });
  const [rowValues, setRowValues] = useState<any[]>([]);
  const [rowDataSourceValues, setDataSourceRowValues] = useState<any[]>([]);
  const [dataSources, setDataSources] = useState<DataSourceModel[]>([]);
  const [dataSourceVersions, setDataSourceVersions] = useState<DataSourceVersionModel[]>([]);

  const [selectedFlowPropertyId, setSelectedFlowPropertyId] = useState<string | undefined>(
    epd?.productFlow?.flowPropertyItem?.flowPropertyId
  );
  const [selectedConversionFactor, setSelectedConversionFactor] = useState<number | undefined>(
    epd?.productFlow?.flowPropertyItem?.conversionFactor
  );

  useEffect(() => {
    const fetchDataSourceVersions = async () => {
      const res = await DataSourceVersionsService.getDataSourceVersions();
      setDataSourceVersions(res);
      return res;
    };

    const fetchDataSources = async () => {
      const res = await DataSourcesService.getDataSources();
      setDataSources(res);
      fetchDataSourcesRows();
      return res;
    };

    const fetchDataSourcesRows = async () => {
      setDataSourceRowValues(epd?.epdDataSources ?? []);
    };

    const fetchMaterialProperties = async () => {
      const epdMaterialProperties = epd?.productFlow?.materialPropertyItems?.map((item) => {
        const res2 = materialProperties.find((x) => x.id === item.materialPropertyId);
        return {
          id: res2?.id,
          name: res2?.name,
          value: item.value,
        };
      });
      setRowValues(epdMaterialProperties ?? []);
    };

    // TODO refactor after, copied and adapted form old wizard
    const fetchFlowProperties = async () => {
      const flowPropertyId = epd?.productFlow?.flowPropertyItem?.flowPropertyId;
      const res2 = flowProperties.find((item) => item.id === flowPropertyId);
      setFlowPropertyOption({ value: flowPropertyId, label: res2?.name });
    };

    fetchMaterialProperties();
    fetchFlowProperties();
    fetchDataSources();
    fetchDataSourceVersions();
    fetchDataSourcesRows();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  type SubtypeOption = {
    label: Subtype;
    value: Subtype | undefined;
  };

  const subtypeOptions: SubtypeOption[] = (Object.keys(Subtype) as Array<keyof typeof Subtype>).map((key) => ({
    label: Subtype[key],
    value: Subtype[key],
  }));

  const changeProductFlowValues = (name?: string, flowPropertyId?: string, conversionFactor?: number) => {
    let newValue: any = {
      Name: name,
    };
    if (newValue.Name === '') {
      return newValue;
    }
    if (flowPropertyId && conversionFactor) {
      newValue = {
        ...newValue,
        FlowPropertyItem: {
          FlowPropertyId: flowPropertyId,
          ConversionFactor: conversionFactor,
        },
      };
    }
    return newValue;
  };

  const saveRowValues = (values: any[]) => {
    const filteredRows = values.filter((x) => x.id !== '' && x.value !== '');
    const valuesToSave: any = filteredRows.map((row) => {
      return {
        MaterialPropertyId: row.id,
        Value: row.value,
      };
    });
    onChangeEpd('MaterialProperties', valuesToSave);
  };

  const saveDataSourceRowValues = (values: any[]) => {
    const valuesToSave: any = values.map((row) => {
      return {
        Id: row.id,
        DataSource: { id: row.dataSource.id, name: row.dataSource.name },
        DataSourceVersion: { id: row.dataSourceVersion.id, name: row.dataSourceVersion.name },
      };
    });
    onChangeEpd('DataSourcesProperties', valuesToSave);
  };

  function handleProductFlowValueChange(event: any) {
    const { value } = event.target;
    const formattedProductFlowValue = value.replace(/^0+(?=\d)/, ''); // Removes leading zeros, except one before a period
    event.target.value = formattedProductFlowValue;
  }

  const handleProductFlowValueKeyDown = (event: any) => {
    const {
      code,
      target: { value },
    } = event;
    const fractionalNumWithOneDigitPattern = /^0\.\d$/;
    if (code === 'Backspace' && fractionalNumWithOneDigitPattern.test(value)) {
      event.target.value = '';
      return;
    }
    if (code !== 'Minus') {
      return;
    }
    event.preventDefault();
  };

  const handleProductFlowValuePaste = (event: any) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.Clipboard;
    const pastedValue = clipboardData.getData('text');

    if (pastedValue === '0') {
      // Inserts the text at the current caret position
      document.execCommand('insertText', false, '0.01');
      return;
    }

    let formattedProductFlowValue = pastedValue
      .replace(/^0+(?=\d)/, '') // Removes leading zeros, except one before a period
      .replace(/-/g, ''); // Removes minuses

    document.execCommand('insertText', false, formattedProductFlowValue);
  };

  const handleChangedProductFlow = (fieldName: string, value: any) => {
    setSelectedConversionFactor(value);
    onChangeEpd(fieldName, changeProductFlowValues(epd?.productFlow?.name, selectedFlowPropertyId, value));
  };

  const onChangeMaterialUnit = (id: string, index: number) => {
    const newValues = [...rowValues];
    newValues[index] = { value: newValues[index].value, id: id, name: materialProperties.find((x) => x.id == id)?.name };
    setRowValues(newValues);
    saveRowValues(newValues);
  };

  const onChangeMaterialValue = (value: number, index: number, save: boolean = false) => {
    const newValues = [...rowValues];
    newValues[index].value = value;
    setRowValues(newValues);
    if (save) {
      saveRowValues(newValues);
    }
  };

  const onChangeDataSource = (id: any, index: number, propertyName: string) => {
    const newValues = [...rowDataSourceValues];

    if (propertyName === 'DataSource') {
      newValues[index].dataSource = { id: id, name: dataSources.find((x) => x.id == id)?.name };
      newValues[index].dataSourceVersion = { id: undefined, name: '' };
    } else if (propertyName === 'DataSourceVersion') {
      newValues[index].dataSourceVersion = { id: id, name: dataSourceVersions.find((x) => x.id == id)?.name };
    }
    setDataSourceRowValues(newValues);
    saveDataSourceRowValues(newValues);
  };

  const createRow = () => {
    const newRowValues = [...rowValues, { id: '', name: '', value: '' }];
    setRowValues(newRowValues);
  };

  const deleteRow = (index: number) => {
    const newValues = [...rowValues];
    newValues.splice(index, 1);
    setRowValues([...newValues]);
    saveRowValues(newValues);
  };

  const createDataSourceRow = () => {
    const newRowValues = [
      ...rowDataSourceValues,
      { id: undefined, dataSource: { id: undefined, name: '' }, dataSourceVersion: { id: undefined, name: '' } },
    ];
    setDataSourceRowValues(newRowValues);
  };

  const deleteDataSourceRow = (index: number) => {
    const newValues = [...rowDataSourceValues];
    newValues.splice(index, 1);
    setDataSourceRowValues([...newValues]);
    saveDataSourceRowValues(newValues);
  };

  return (
    <EditDialog header="Information for machine readable EPDs" isOpened={isOpened} onHide={onHide} maximizable>
      <HelpBox>
        Please fill-in the required information below. The filled data will be auto saved and available in the table.
      </HelpBox>
      <FieldPanelInput>
        <WizardTextInputComponent
          label="Global trade item number"
          name="gtin"
          tooltip="Global Trade Item Number (GTIN) can be used by a company to uniquely identify all of its trade items."
          value={epd?.gtin}
          onChanged={onChangeEpd}
          type="number"
        />

        <HorizontalDivider />

        <FieldContainer>
          <LabelGroup>
            <span style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>{'REFERENCE FLOW(S)'}</span>
            <i className="pi pi-info-circle" />
          </LabelGroup>
          <HelpBox style={{ marginTop: 0 }}>{'Example: Cement (CEM II 32.5). Unit: Mass (kg). Value: 1'}</HelpBox>
          <FieldContainer>
            <WizardTextInputComponent
              label="Product flow name"
              required={isConstruction}
              name="productFlow"
              tooltip="Please provide product flow name, select unit, and provide a value."
              value={epd?.productFlow?.name}
              onChanged={(fieldName: string, value: any) => {
                onChangeEpd(
                  fieldName,
                  changeProductFlowValues(value ? value : '', selectedFlowPropertyId, selectedConversionFactor)
                );
              }}
            />
          </FieldContainer>
        </FieldContainer>
      </FieldPanelInput>
      <FieldContainer>
        <FieldPanel>
          <WizardSelectComponent
            label="Unit"
            tooltip="Indicates the declared unit or functional unit."
            tooltipPosition="right | top"
            name="productFlow"
            options={flowProperties.map((x: FlowPropertyModel) => {
              return { value: x.id, label: x.name } as Option;
            })}
            value={flowPropertyOption as Option}
            required={isConstruction}
            onChanged={(propName: string, value: any) => {
              if (value) {
                setFlowPropertyOption({ value: value, label: flowProperties.find((x) => x.id == value)?.name });
                setSelectedFlowPropertyId(value);
                onChangeEpd(propName, changeProductFlowValues(epd?.productFlow?.name, value, selectedConversionFactor));
              } else {
                setFlowPropertyOption({ value: undefined, label: undefined });
                setSelectedConversionFactor(undefined);
                onChangeEpd(propName, changeProductFlowValues(epd?.productFlow?.name, undefined, undefined));
              }
            }}
          />
          <WizardTextInputComponent
            label="Value"
            type="number"
            min="0.1"
            step="0.1"
            required={isConstruction}
            name="productFlow"
            tooltip="Numeric field."
            onKeyDown={handleProductFlowValueKeyDown}
            onPaste={handleProductFlowValuePaste}
            onChange={handleProductFlowValueChange}
            onChanged={(propName: string, value: any) => {
              handleChangedProductFlow(propName, value as any);
            }}
            value={selectedConversionFactor}
          />
        </FieldPanel>
      </FieldContainer>

      <HorizontalDivider />

      <FieldContainer>
        <FieldPanelInput>
          <LabelGroup>
            <span style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>{'MATERIAL PROPERTIES'}</span>
            <i className="pi pi-info-circle" />
          </LabelGroup>
          <HelpBox>
            Declaration of relevant non-scaling physical or chemical product properties. Up to six properties can be added
            with the following units.
            <br />
            Example: Property (Unit): Bulk density (kg/m³). Value: 1
          </HelpBox>
        </FieldPanelInput>
      </FieldContainer>
      <FieldPanel style={{ rowGap: 0 }}>
        {rowValues.map((item, index) => (
          <React.Fragment key={index}>
            <WizardSelectComponent
              options={materialProperties
                .map((x: MaterialPropertyModel) => {
                  return { value: x.id, label: x.name } as Option;
                })
                .filter((option: Option) => !rowValues.find((rowValue) => rowValue.id === option.value))}
              value={{ value: item?.id, label: item?.name }}
              label="Property(unit)"
              tooltip="Select unit and provide a value."
              isClearable={true}
              name={'MaterialProperties'}
              onChanged={(propName: string, value: any) => onChangeMaterialUnit(value, index)}
            />
            <div className="flex">
              <WizardTextInputComponent
                value={item.value}
                type="number"
                label="value"
                tooltip="Numeric field"
                placeholder={t('placeholder.insertNumber')}
                name={'MaterialProperties'}
                onChanged={(propName: string, value: any) => onChangeMaterialValue(value, index, true)}
              />

              <TextButton
                style={{ alignSelf: 'flex-end', margin: '0.25rem' }}
                type="button"
                text
                icon="pi pi-trash"
                onClick={() => deleteRow(index)}
                ///disabled={epd?.referenceFlowsState?.actionState === ActionState.Disabled}
              />
            </div>
          </React.Fragment>
        ))}
      </FieldPanel>
      {rowValues.length < 6 && (
        <TextButton
          className="white-space-nowrap"
          style={{ cursor: 'pointer', paddingTop: '3px' }}
          label={'+ Add property'}
          text
          onClick={createRow}
        />
      )}

      <HorizontalDivider />

      <FieldContainer>
        <WizardTextInputComponent
          label="GEOGRAPHICAL SCOPE DESCRIPTION"
          value={epd?.geographicalDescription}
          onChanged={onChangeEpd}
          name="geographicalDescription"
          tooltip="Explanations about additional aspects of the location if relevant."
        />
      </FieldContainer>

      <HorizontalDivider />

      <FieldContainer>
        <WizardTextAreaComponent
          label="TECHNOLOGY DESCRIPTION INCLUDING BACKGROUND SYSTEM"
          required={isConstruction}
          value={epd?.technologyDescription}
          onChanged={onChangeEpd}
          name="technologyDescription"
          tooltip="Technological characteristics including operating conditions of the product system or process."
          tooltipPosition="left"
        />
      </FieldContainer>
      <HorizontalDivider />
      <FieldContainer>
        <WizardTextAreaComponent
          label="TECHNICAL PURPOSE OF PRODUCT OR PROCESS"
          required={isConstruction}
          value={epd?.technicalPurpose}
          onChanged={onChangeEpd}
          name="technicalPurpose"
          tooltip={'Brief description of the intended use / possible applications of the goods, service, or process.'}
        />
      </FieldContainer>
      <HorizontalDivider />

      <FieldContainer>
        <WizardSelectComponent
          isClearable={false}
          required
          label="Subtype"
          tooltip="Indicates the type of data set regarding its representativeness."
          tooltipPosition="top | right"
          options={subtypeOptions}
          name="subtype"
          value={{ value: epd?.subtype, label: Subtype[epd?.subtype as keyof typeof Subtype] }}
          onChanged={onChangeEpd}
        />
      </FieldContainer>

      <HorizontalDivider />

      <FieldContainer>
        <FieldPanelInput>
          <LabelGroup>
            <span style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>{'DATA SOURCES USED FOR THIS DATA SET'}</span>
            <i className="pi pi-info-circle" />
            <SmartLabel
              label={''}
              required={isConstruction}
              hasValue={rowDataSourceValues && rowDataSourceValues.length > 0}
            />
          </LabelGroup>
          <HelpBox>{'Select main data source and specific version of the background database used for the EPD.'}</HelpBox>
        </FieldPanelInput>
      </FieldContainer>

      <FieldPanel style={{ rowGap: 0 }}>
        {rowDataSourceValues.map((item, index) => (
          <React.Fragment key={index}>
            <WizardSelectComponent
              options={dataSources.map((x: DataSourceModel) => {
                return { value: x.id, label: x.name } as Option;
              })}
              value={item?.dataSource?.id ? { value: item?.dataSource?.id, label: item?.dataSource?.name } : null}
              // isDisabled={epd?.epdDataSourcesState?.actionState === ActionState.Disabled}
              isClearable
              placeholder={t('placeholder.defaultSelectDatabase')}
              name={'DataSource'}
              label="Database"
              tooltip="Background database used for the EPD."
              tooltipPosition="right"
              required
              onChanged={(propName: string, value: any) => onChangeDataSource(value as any, index, propName)}
            />
            <div className="flex">
              <WizardSelectComponent
                options={dataSourceVersions
                  .filter((dataSourceVersionItem) => dataSourceVersionItem.dataSourceId === item?.dataSource?.id)
                  .map((x: DataSourceVersionModel) => {
                    return { value: x.id, label: x.name, dataSourceId: x.dataSourceId } as Option;
                  })}
                value={
                  item?.dataSourceVersion?.id
                    ? { value: item?.dataSourceVersion?.id, label: item?.dataSourceVersion?.name }
                    : null
                }
                ///isDisabled={!item?.dataSource?.id || epd?.epdDataSourcesState?.actionState === ActionState.Disabled}
                isClearable
                name="DataSourceVersion"
                label="Version"
                tooltip="Select specific version of the background database"
                required
                placeholder={t('placeholder.defaultSelectDatabaseVersion')}
                onChanged={(propName: string, value: any) => onChangeDataSource(value as any, index, propName)}
              />
              <TextButton
                style={{ alignSelf: 'flex-end', margin: '0.25rem' }}
                text
                icon="pi pi-trash"
                onClick={() => deleteDataSourceRow(index)}
                ///disabled={epd?.epdDataSourcesState?.actionState === ActionState.Disabled}
              />
            </div>
          </React.Fragment>
        ))}
      </FieldPanel>
      {rowDataSourceValues.length < 6 && (
        <TextButton
          className="white-space-nowrap"
          style={{ cursor: 'pointer', paddingTop: '3px' }}
          label={'+ Add data source'}
          text
          onClick={createDataSourceRow}
        />
      )}

      <HorizontalDivider />

      <FieldContainer>
        <WizardTextAreaComponent
          label="USE ADVICE FOR DATA SET"
          labelStyle={{ fontWeight: 'bold' }}
          required={isConstruction}
          value={epd?.useAdvice}
          onChanged={onChangeEpd}
          name="useAdvice"
          tooltip="Specification on how the EPD is to be used."
        />
      </FieldContainer>
    </EditDialog>
  );
};

const FieldPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: start;
  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${(props) => props.theme.media.desktop} {
    grid-template-columns: 1fr 1fr;
  }
`;

export default EditMachineReadableDialog;
